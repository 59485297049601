import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import ModalAppBar from "./ModalAppBar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { modalStyles } from "./styles";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { REJECT_REASON } from "../../../App/common/constant";

const {
  REJECT_REASON_IMAGE_BULLRY,
  REJECT_REASON_IMAGE_NOT_VISIBLE,
  REJECT_REASON_IMAGE_WRONG,
  REJECT_REASON_IMAGE_INVALIDPRICE,
  REJECT_REASON_IMAGE_TOPPINGS_NOTSUPPORT,
  REJECT_REASON_IMAGE_WRONG_CONTENT,
  REJECT_REASON_IMAGE_CONFLICT_INFORMATION,
  REJECT_REASON_IMAGE_OTHER,
} = REJECT_REASON;
const useStyles = makeStyles(modalStyles);

export const RejectModal = ({ open, handleClose, handleReject, isSaving }) => {
  /*let [form, setForm] = useState({
    menu_not_provide: false,
    wrong_category: false,
    typos: false,
    incorrect_langauge: false,
    unreasonable_price: false,
    other: true,
  });*/

  const [rejectOption, setRejectOption] = useState(REJECT_REASON_IMAGE_OTHER);
  const [rejectReason, setRejectReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [otherReasonError, setOtherReasonError] = useState("");
  const classes = useStyles();

  const handleChange = e => {
    if (e.target.value === REJECT_REASON_IMAGE_OTHER) {
      setRejectOption(e.target.value);
      setRejectReason(otherReason);
      return;
    }
    setRejectOption(e.target.value);
    setRejectReason(e.target.value);
  };

  useEffect(() => {
    if (
      rejectOption === REJECT_REASON_IMAGE_OTHER &&
      otherReason.trim() === ""
    ) {
      setOtherReasonError("Please state the reason for rejection");
    } else {
      setOtherReasonError("");
    }
  }, [rejectOption, otherReason]);

  /*const handleToggle = key => {
    setForm({
      ...form,
      [key]: !form[key],
    });
  };*/

  const submitReject = () => {
    if (
      rejectOption === REJECT_REASON_IMAGE_OTHER &&
      rejectReason.trim() === ""
    ) {
      return;
    }
    if (rejectReason === "") {
      // dynamoDB save empty string as null
      return handleReject(null);
    }
    handleReject(rejectReason);
  };
  return (
    <Modal open={open} onClose={handleClose} disableAutoFocus={true}>
      <Box
        overflow="scroll"
        bgcolor="white"
        minWidth={700}
        height="100%"
        className={classes.root}
      >
        <ModalAppBar
          onClose={handleClose}
          handleReject={submitReject}
          isSaving={isSaving}
        />
        <Box component="form" p={3} display="flex" flexDirection="column">
          <RadioGroup
            aria-label="Reject Options"
            name="rejectOptions"
            value={rejectOption}
            onChange={handleChange}
          >
            <Box>
              <FormControlLabel
                control={<Radio />}
                value={REJECT_REASON_IMAGE_BULLRY}
                label={REJECT_REASON_IMAGE_BULLRY}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Radio />}
                value={REJECT_REASON_IMAGE_NOT_VISIBLE}
                label={REJECT_REASON_IMAGE_NOT_VISIBLE}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Radio />}
                value={REJECT_REASON_IMAGE_WRONG}
                label={REJECT_REASON_IMAGE_WRONG}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Radio />}
                value={REJECT_REASON_IMAGE_INVALIDPRICE}
                label={REJECT_REASON_IMAGE_INVALIDPRICE}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Radio />}
                value={REJECT_REASON_IMAGE_TOPPINGS_NOTSUPPORT}
                label={REJECT_REASON_IMAGE_TOPPINGS_NOTSUPPORT}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Radio />}
                value={REJECT_REASON_IMAGE_WRONG_CONTENT}
                label={REJECT_REASON_IMAGE_WRONG_CONTENT}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Radio />}
                value={REJECT_REASON_IMAGE_CONFLICT_INFORMATION}
                label={REJECT_REASON_IMAGE_CONFLICT_INFORMATION}
              />
            </Box>
            <Box flex={1} display="flex" flexDirection="column">
              <FormControlLabel
                control={<Radio />}
                value={REJECT_REASON_IMAGE_OTHER}
                label={REJECT_REASON_IMAGE_OTHER}
              />
              <TextField
                multiline
                disabled={
                  rejectOption !== REJECT_REASON_IMAGE_OTHER ? true : false
                }
                value={otherReason}
                onChange={e => {
                  setOtherReason(e.target.value);
                  setRejectReason(e.target.value);
                }}
                error={otherReasonError.length > 0}
                helperText={otherReasonError}
                inputProps={{ "data-hj-whitelist": true }}
              />
            </Box>
          </RadioGroup>
        </Box>
      </Box>
    </Modal>
  );
};

ModalAppBar.propTypes = {
  handleClose: PropTypes.func,
  handleReject: PropTypes.func,
  isSaving: PropTypes.bool,
};
export default RejectModal;
