import ManagePhoto from "./containers/ManagePhoto";

const PhotoManagement = () => {
  return {
    id: "photo-management",
    pages: [
      {
        path: "/dish-photos",
        showOnSideBar: true,
        component: ManagePhoto,
        label: "Dish Photos",
        isProtected: true,
      },
    ],
  };
};

export default PhotoManagement;
