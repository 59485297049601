const REJECT_REASON_IMAGE_BULLRY =
  "Price change cannot be validated / Menu Photo submitted is not clear or does not contain price information. Please try again and submit a new menu photo with prices.";
const REJECT_REASON_IMAGE_NOT_VISIBLE =
  "The price is not visible in the photo. Please try again and submit a new menu photo with prices.";
const REJECT_REASON_IMAGE_WRONG =
  "Wrong photo provided. Please try again and submit a new menu photo with prices.";
const REJECT_REASON_IMAGE_INVALIDPRICE =
  "Invalid price markup. Please ensure that your price matches your menu.";
const REJECT_REASON_IMAGE_TOPPINGS_NOTSUPPORT =
  "Toppings cannot be modified at this time. Please submit a formstack request to edit your toppings.";
const REJECT_REASON_IMAGE_WRONG_CONTENT =
  "Content provided doesn't match the attribute.";
const REJECT_REASON_IMAGE_CONFLICT_INFORMATION =
  "Conflicting information between the product and description fields.";
const REJECT_REASON_IMAGE_OTHER = "Other";

export const REJECT_REASON = {
  REJECT_REASON_IMAGE_BULLRY,
  REJECT_REASON_IMAGE_NOT_VISIBLE,
  REJECT_REASON_IMAGE_WRONG,
  REJECT_REASON_IMAGE_INVALIDPRICE,
  REJECT_REASON_IMAGE_TOPPINGS_NOTSUPPORT,
  REJECT_REASON_IMAGE_WRONG_CONTENT,
  REJECT_REASON_IMAGE_CONFLICT_INFORMATION,
  REJECT_REASON_IMAGE_OTHER,
};
