import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import styles from "./styles";

const DatePicker = ({ classes, label, onChange, value }) => {
  return (
    <div className={classes.container}>
      <TextField
        type="date"
        label={label}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  classes: PropTypes.any.isRequired,
};

export default withStyles(styles)(DatePicker);
