import * as React from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import Form from "./Form";
import ModalAppBar from "./ModalAppBar";
import ChangeDetail from "../ChangeDetail";
import HighLightBox from "../HighlightBox";
import StatusBox from "../StatusBox";
import {
  capitalizeFirstLetter,
  getDefaultLanguage,
} from "../../../utility/information";
import { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { modalStyles } from "./styles";
import { makeStyles } from "@material-ui/core/styles";
import { TYPE_OF_CHANGE } from "../../../App/common/constant";
import { Provider } from "../../context/choiceGroups";
import { useThunkReducer } from "../../../App/hooks";
import reducer from "../../store/choiceGroups/reducer";
import { fetchChoiceGroupsAsync } from "../../store/choiceGroups/actions";
import {
  getCategoryChangeObject,
  getCategoryObject,
  getDescriptionChangeObject,
  getTitleChangeObject,
  getVariantChoiceGroupAssociationsChange,
  getVariantsChange,
  hasCategoryChange,
  hasDescriptionChange,
  hasTitleChange,
  getChoiceGroupObject,
} from "../../utility/information";

const {
  VARIANT_UNITPRICE_CHANGE,
  CATEGORY_CHANGE,
  VARIANT_CREATE,
  VARIANT_DELETE,
  PRODUCT_CREATE,
  VARIANT_CHOICE_GROUP_ADD,
  VARIANT_CHOICE_GROUP_DELETE,
} = TYPE_OF_CHANGE;

const useStyles = makeStyles(modalStyles);

const ProductDetailModal = ({
  product,
  open,
  handleClose,
  handleSave,
  isSaving,
  currentCountry,
  categories,
}) => {
  const title = getTitleChangeObject(product);
  const variantsChange = getVariantsChange(product);
  const description = getDescriptionChangeObject(product);
  const category = getCategoryChangeObject(product);
  const variantChoiceGroupAssociation = getVariantChoiceGroupAssociationsChange(
    product,
  );
  const classes = useStyles();
  const [form, setForm] = useState({
    title,
    description,
    variants: variantsChange,
    category,
  });
  const changeInfo = product.changeInfo;
  const currency =
    changeInfo &&
    product.changeInfo.currency &&
    product.changeInfo.currency.code;
  const vendorId = changeInfo && changeInfo.vendor_id;
  const [state, dispatch] = useThunkReducer(reducer, {
    loading: false,
    data: [],
  });

  useEffect(
    function resetField() {
      setForm(form => {
        return {
          ...form,
          description,
          title,
          variants: variantsChange,
          category,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, product],
  );

  useEffect(
    function fetchVendorChoiceGroups() {
      const globalEntityId = product?.changeInfo?.code ?? "FP_SG";
      dispatch(fetchChoiceGroupsAsync({ vendorId, globalEntityId }));
    },
    [product, vendorId, dispatch],
  );

  const handleChange = (e, language) => {
    let { name, value } = e.target;

    setForm(form => {
      return { ...form, [name]: { ...form[name], [language]: value } };
    });
  };

  const handleCategoryChange = e => {
    let { name, value } = e.target;

    setForm(form => {
      return { ...form, [name]: value };
    });
  };

  const updateVariant = (variantId = NaN, updatedValue) => {
    let variantIndex = form.variants.findIndex(v => v.id === variantId);
    let isCreatingNewProduct = false;

    // Which is new product created
    if (variantIndex < 0) {
      variantIndex = variantId;
      isCreatingNewProduct = true;
    }

    const newVariant = { ...form.variants[variantIndex], ...updatedValue };

    let newVariants = form.variants.map((v, index) => {
      if (!isCreatingNewProduct && newVariant.id === v.id) return newVariant;
      // Which is new product created
      if (isCreatingNewProduct && index === variantIndex) return newVariant;
      return v;
    });

    setForm(prev => ({
      ...prev,
      variants: newVariants,
    }));
  };

  const handlePriceChange = (e, variantId) => {
    const { value } = e.target;
    const unitPrice = +value;

    updateVariant(variantId, { unitPrice });
  };

  const handleVariantionNameChange = (e, variantId, language) => {
    const { value, name } = e.target;
    let variantIndex = form.variants.findIndex(v => v.id === variantId);

    // Which is new product created
    if (variantIndex < 0) {
      variantIndex = variantId;
    }

    let title = {
      ...(form.variants[variantIndex] && form.variants[variantIndex][name]),
      [language]: value,
    };

    updateVariant(variantId, { title });
  };

  const handleSubmit = () => {
    handleSave(product, form);
  };

  const getValuesForChangeDetail = change => {
    switch (change.type_of_change) {
      case VARIANT_UNITPRICE_CHANGE: {
        const currencyText = currency ? `${currency} ` : "";
        return [
          change.old_value &&
            `${currencyText}${(+change.old_value).toFixed(2)}`,
          change.new_value &&
            `${currencyText}${(+change.new_value).toFixed(2)}`,
        ];
      }
      case CATEGORY_CHANGE: {
        let oldValue = getCategoryObject(categories, change.old_value);
        let newValue = getCategoryObject(categories, change.new_value);
        let defaultLanguage = "";

        if (oldValue || newValue) {
          defaultLanguage = oldValue
            ? getDefaultLanguage(oldValue.title)
            : getDefaultLanguage(newValue.title);
        }
        return [
          oldValue ? oldValue.title[defaultLanguage] : change.old_value,
          newValue ? newValue.title[defaultLanguage] : change.new_value,
        ];
      }
      case VARIANT_CREATE: {
        const { title, unitPrice, containerPrice } = change;
        // TODO: add containerPrice to array back once supported
        const prices = [unitPrice].map(
          p => `${currency} ${p ? p.toFixed(2) : 0}`,
        );
        return ["-", [...prices, ...Object.values(title || {})].join(", ")];
      }
      case VARIANT_DELETE: {
        const { variant_id } = change;
        const deleteVariant = variantsChange.find(v => v.id === variant_id);
        if (!deleteVariant) return ["-", "-"];
        return [
          `${currency} ${deleteVariant.unitPrice.toFixed(2)}, ${Object.values(
            deleteVariant.title,
          ).join(", ")}`,
          "-",
        ];
      }
      case PRODUCT_CREATE: {
        return ["-", "-"];
      }
      case VARIANT_CHOICE_GROUP_ADD: {
        const newValue = getChoiceGroupObject(
          state.data,
          change.choice_group_id,
        );
        const defaultLanguage = getDefaultLanguage(newValue.title);
        return [
          "-",
          newValue?.title?.[defaultLanguage] ?? change.choice_group_id,
        ];
      }
      case VARIANT_CHOICE_GROUP_DELETE: {
        const oldValue = getChoiceGroupObject(
          state.data,
          change.choice_group_id,
        );
        const defaultLanguage = getDefaultLanguage(oldValue.title);
        return [
          oldValue?.title?.[defaultLanguage] ?? change.choice_group_id,
          "-",
        ];
      }
      default:
        return [change.old_value, change.new_value];
    }
  };
  return (
    <Modal open={open} onClose={handleClose} disableAutoFocus={true}>
      <Box
        height="100%"
        minWidth={700}
        maxWidth={700}
        overflow="scroll"
        bgcolor="white"
        className={classes.root}
        data-cp="product-detail-modal"
      >
        <ModalAppBar
          onClose={handleClose}
          handleSave={handleSubmit}
          isFormDirty={true}
          isSaving={isSaving}
        />
        {product.changeInfo && (
          <Box p={3}>
            <HighLightBox type={product.changeInfo.status}>
              <Box display="flex" p={1}>
                <Box
                  flex={1}
                  justifyContent="center"
                  fontSize={18}
                  lineHeight="28px"
                >
                  {capitalizeFirstLetter(product.changeInfo.status)} Requests
                </Box>
                <Box flex={0}>
                  <StatusBox type={product.changeInfo.status} />
                </Box>
              </Box>

              {product.changeInfo.changes.map((change, i) => {
                const [oldValue, newValue] = getValuesForChangeDetail(change);
                return (
                  <ChangeDetail
                    key={change.id}
                    label={change.type_of_change}
                    date={change.updatedOn}
                    oldValue={oldValue}
                    newValue={newValue}
                    language={change.language}
                  />
                );
              })}
            </HighLightBox>
          </Box>
        )}
        <Box p={3}>
          <Provider
            value={{
              choiceGroups: state.data,
            }}
          >
            <Form
              title={form.title}
              hasTitleChange={hasTitleChange(product)}
              hasDescriptionChange={hasDescriptionChange(product)}
              hasCategoryChnge={hasCategoryChange(product)}
              description={form.description}
              category={form.category}
              variants={form.variants}
              variantChoiceGroupAssociation={variantChoiceGroupAssociation}
              handleChange={handleChange}
              handleCategoryChange={handleCategoryChange}
              handlePriceChange={handlePriceChange}
              handleVariantionNameChange={handleVariantionNameChange}
              currency={currency || ""}
              currentCountry={currentCountry}
              categories={categories}
            />
          </Provider>
        </Box>
      </Box>
    </Modal>
  );
};

ProductDetailModal.propTypes = {
  product: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  isSaving: PropTypes.bool,
  categories: PropTypes.array,
};

export default ProductDetailModal;
