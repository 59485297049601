import { createStyles } from "@material-ui/core/styles";

const styles = () =>
  createStyles({
    bodyContainer: {
      background: "white",
      minWidth: 700,
      maxWidth: 700,
      height: 2000,
      maxHeight: "100%",
      float: "right",
      overflow: "scroll",
      "@media (max-width: 700px)": {
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
      },
    },
    datePickerWrapper: {
      display: "flex",
      flexDirection: "row",
      "@media (max-width: 700px)": {
        flexDirection: "column",
      },
    },
    datePickerBox: {
      paddingLeft: 32,
      paddingRight: 32,
      "@media (max-width: 700px)": {
        padding: 0,
      },
    },
  });

export const radioStyles = theme =>
  createStyles({
    root: {
      display: "flex",
    },
    formControl: {
      margin: theme.spacing(3),
      display: "flex",
      marginLeft: 0,
    },
    group: {
      margin: `${theme.spacing(1)}px 0`,
      flexDirection: "row",
    },
  });

export const modalStyles = () => {
  createStyles({
    root: {
      flexGrow: 1,
    },
    flex: {
      flexGrow: 1,
      flex: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
  });
};
export default styles;
