import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import PropTypes from "prop-types";
import { radioStyles } from "./styles";

const ClosedAllDayPicker = ({
  classes,
  handleSetCloseAllDay,
  isClosedAllDay,
}) => {
  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <FormLabel>Closed the whole day ?</FormLabel>
        <RadioGroup
          aria-label="Gender"
          name="gender1"
          className={classes.group}
          value={isClosedAllDay}
          onChange={handleSetCloseAllDay}
        >
          <FormControlLabel
            value="yes"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            value="no"
            control={<Radio color="primary" />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

ClosedAllDayPicker.propTypes = {
  handleSetCloseAllDay: PropTypes.func.isRequired,
  isClosedAllDay: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(radioStyles)(ClosedAllDayPicker);
