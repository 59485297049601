import { createStyles } from "@material-ui/core/styles";

const styles = () =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: 0,
      marginRight: 0,
      width: 200,
    },
  });

export default styles;
