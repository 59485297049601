import React, { useState, useEffect, useCallback } from "react";
import axios from "../../../App/utility/axios";
import config from "../../../App/config";
import Cookies from "js-cookie";
import { Modal } from "@deliveryhero/fp-apollo-components";
import { ChoiceGroupDetailSection } from "@deliveryhero/fp-apollo-components";
import { ChoicesSection } from "@deliveryhero/fp-apollo-components";
import { TYPE_OF_CHANGE_CHOICE_GROUP } from "../../../App/common/constant";
import Translate from "../../../utility/Translate";
import { capitalizeFirstLetter } from "../../../utility/information";
import Box from "@material-ui/core/Box";
import HighLightBox from "../../components/HighlightBox";
import StatusBox from "../../components/StatusBox";
import ChangeDetail from "../../components/ChangeDetail";
import {
  getChoiceGroupTitleChange,
  getChoiceGroupQuantityChange,
  getChoiceGroupPickedChoices,
  withChangesGroupByTypeOfChange,
} from "../../utility/information";
import { choiceGroupHighlightBoxStyle } from "../../components/HighlightBox/styles";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

const {
  CHOICE_GROUP_CREATE,
  CHOICE_GROUP_QUANTITY_CHANGE,
  CHOICE_GROUP_TITLE_CHANGE,
  CHOICES_ADD,
  CHOICES_DELETE,
  CHOICES_CREATE,
  CHOICES_PRICE_CHANGE,
} = TYPE_OF_CHANGE_CHOICE_GROUP;

const useChoiceGroupHighlightBoxStyles = makeStyles(
  choiceGroupHighlightBoxStyle,
);

const ChoiceGroupDetailModal = ({
  choiceGroupModalOpen,
  handleChoiceGroupModalClose,
  choiceGroupChanges,
  handleChoiceProductUpdate,
  // TODO temp fix, need to refactor!!!
  setChoiceGroupNotification,
}) => {
  const choiceGroupHighlightBoxClasses = useChoiceGroupHighlightBoxStyles();
  const [choiceGroupDetailTitle, setChoiceGroupDetailTitle] = useState({});
  const [choiceGroupDetailQuantity, setChoiceGroupDetailQuantity] = useState({
    minimum: "",
    maximum: "",
  });
  const [pickedChoices, setPickedChoices] = useState([]);
  const [choiceProduct, setChoiceProduct] = useState({});

  // TODO move the fetching logic to top level place, eg: Reducer
  /**
   * Set choiceGroupDetailTitle from choiceGroupChanges based on the change type
   */
  useEffect(() => {
    const choiceGroupRequestPayload = choiceGroupChanges.choiceProduct;
    const titleChange = getChoiceGroupTitleChange(choiceGroupRequestPayload);
    const quantityChange = getChoiceGroupQuantityChange(
      choiceGroupRequestPayload,
    );

    const pickedChoices = getChoiceGroupPickedChoices(
      choiceGroupRequestPayload,
    );

    setChoiceGroupDetailTitle(titleChange);
    setChoiceGroupDetailQuantity(quantityChange);
    setPickedChoices(pickedChoices);

    if (choiceGroupChanges?.choiceProduct) {
      setChoiceProduct(choiceGroupChanges.choiceProduct);
    }
  }, [choiceGroupChanges]);

  /**
   * Title change handler
   */
  const handleTitleChange = (e, language) => {
    const value = e.target.value;
    setChoiceGroupDetailTitle(prev => ({
      ...prev,
      [language]: value,
    }));
  };

  const handleMaximumChange = e => {
    const value = e.target.value;
    setChoiceGroupDetailQuantity(prev => ({
      ...prev,
      maximum: value,
    }));
  };

  const handleMinimumChange = e => {
    const value = e.target.value;
    setChoiceGroupDetailQuantity(prev => ({
      ...prev,
      minimum: value,
    }));
  };

  /**
   * Modal save click handler
   */
  const saveData = ({ currentChoiceGroup, isCreate }) => {
    const choiceGroupId = choiceProduct.choice_group_id;
    const API_URL = `${config.api}/internal/choice-groups${
      isCreate ? "" : `/${choiceGroupId}`
    }`;

    return axios(API_URL, {
      method: isCreate ? "post" : "patch",
      data: JSON.stringify(currentChoiceGroup),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("token"),
        "x-global-entity-id": currentChoiceGroup.code,
      },
    });
  };

  const handleSubmit = async () => {
    console.log(pickedChoices);
    const isCreate = choiceProduct.choice_group_id?.startsWith("NEWCG");
    const changesGroupByTypeOfChange = _.groupBy(
      choiceProduct.changes,
      "type_of_change",
    );
    const getChangesByTypeOfChange = withChangesGroupByTypeOfChange(
      changesGroupByTypeOfChange,
    );
    const languageKeys =
      choiceProduct?.languages.map(language => language.code) ?? [];
    const {
      title: originalTitle,
      quantity: originalQuantity,
      // choices: originalChoices
    } = choiceProduct?.choice_group;
    const updatedChanges = [];
    const currentChoiceGroup = _.cloneDeep(choiceProduct);

    if (choiceGroupDetailTitle && !isCreate) {
      const modifiedLanguage = {};
      updatedChanges.push(
        ...getChangesByTypeOfChange(CHOICE_GROUP_TITLE_CHANGE).map(change => {
          modifiedLanguage[change.language] = change.language;
          return {
            ...change,
            new_value: choiceGroupDetailTitle[change.language],
          };
        }),
      );

      languageKeys.forEach(language => {
        if (
          !modifiedLanguage[language] &&
          originalTitle[language] !== choiceGroupDetailTitle[language]
        ) {
          updatedChanges.push({
            old_value: originalTitle[language],
            new_value: choiceGroupDetailTitle[language],
            type_of_change: CHOICE_GROUP_TITLE_CHANGE,
            language,
          });
        }
      });
    }

    if (choiceGroupDetailQuantity && !isCreate) {
      const modifiedQuantity = {};
      updatedChanges.push(
        ...getChangesByTypeOfChange(CHOICE_GROUP_QUANTITY_CHANGE).map(
          change => {
            modifiedQuantity[change.minMax] = change.minMax;
            return {
              ...change,
              new_value: choiceGroupDetailQuantity[change.minMax],
            };
          },
        ),
      );

      ["minimum", "maximum"].forEach(minMax => {
        if (
          !modifiedQuantity[minMax] &&
          originalQuantity[minMax] !== choiceGroupDetailQuantity[minMax]
        ) {
          updatedChanges.push({
            old_value: originalQuantity[minMax],
            new_value: choiceGroupDetailQuantity[minMax],
            type_of_change: CHOICE_GROUP_QUANTITY_CHANGE,
            minMax,
          });
        }
      });
    }

    updatedChanges.push(...getChangesByTypeOfChange(CHOICES_ADD));

    updatedChanges.push(...getChangesByTypeOfChange(CHOICES_DELETE));

    updatedChanges.push(...getChangesByTypeOfChange(CHOICES_CREATE));

    updatedChanges.push(
      ...getChangesByTypeOfChange(CHOICES_PRICE_CHANGE).map(change => {
        const choicesChanged = pickedChoices.find(
          choice => choice?.choice.id === change.id,
        );
        return {
          ...change,
          new_value: choicesChanged ? choicesChanged.price : change.new_value,
        };
      }),
    );

    updatedChanges.push(
      ...getChangesByTypeOfChange(CHOICE_GROUP_CREATE).map(change => ({
        ...change,
        title: choiceGroupDetailTitle,
        quantity: choiceGroupDetailQuantity,
        // TODO placeholder for choices to add edited choices and creating choices
        choices: change.choices,
        newChoices: change.newChoices,
      })),
    );

    currentChoiceGroup.changes = updatedChanges;
    currentChoiceGroup.status = "approved";
    console.log("Nimbus: handleSubmit -> updatedChanges", updatedChanges);

    try {
      await saveData({ currentChoiceGroup, isCreate });
      setChoiceGroupNotification({
        open: true,
        message: "Successfully update choice group request",
        variant: "success",
      });
      handleChoiceGroupModalClose();
      // TODO temp fix for the wrong structure, need to refactor!!!
      // * This line below is causing warning in console
      handleChoiceProductUpdate(currentChoiceGroup);
    } catch (error) {
      setChoiceGroupNotification({
        open: true,
        variant: "error",
        message: error.message,
      });
      console.error(error);
    }
  };

  const getValuesForChangeDetail = change => {
    switch (change.type_of_change) {
      case CHOICES_DELETE: {
        // Get the info of the choice
        // TODO Altitude has send the info of deleted choice,
        // TODO should extract from change
        const { price, title } = choiceProduct?.choice_group?.choices.find(
          choice => choice.id === change.id,
        );
        return [[...Object.values(title), price].join(", "), "-"];
      }
      case CHOICES_ADD: {
        const { price, title } = change;
        return ["-", [...Object.values(title), price].join(", ")];
      }
      case CHOICES_CREATE: {
        const { price, title } = change;
        return ["-", [...Object.values(title), price].join(", ")];
      }
      case CHOICE_GROUP_QUANTITY_CHANGE: {
        return [change.old_value, change.new_value];
      }
      case CHOICE_GROUP_TITLE_CHANGE: {
        return [change.old_value, change.new_value];
      }
      case CHOICE_GROUP_CREATE: {
        const { title, quantity } = change;
        return [
          "-",
          [...Object.values(title), ...Object.values(quantity)].join(", "),
        ];
      }
      default:
        return [change.old_value, change.new_value];
    }
  };

  const getTypeOfChange = (typeOfChange, minMax = "") => {
    if (minMax === "minimum") {
      return "Minimum Quantity Change";
    }

    if (minMax === "maximum") {
      return "Maximum Quantity Change";
    }

    return typeOfChange;
  };

  return (
    <Modal
      open={choiceGroupModalOpen}
      handleClose={handleChoiceGroupModalClose}
      title={Translate("menu_management_pandora.edit_topping_template")}
      buttonText={Translate("menu_management_pandora.save")}
      isFormDirty={true}
      handleButtonClick={handleSubmit}
    >
      {choiceProduct?.changes?.length && (
        <HighLightBox
          type={choiceProduct.status}
          className={choiceGroupHighlightBoxClasses.wrapper}
        >
          <Box display="flex" p={1}>
            <Box
              flex={1}
              justifyContent="center"
              fontSize={18}
              lineHeight="28px"
            >
              {capitalizeFirstLetter(choiceProduct.status)} Requests
            </Box>
            <Box flex={0}>
              <StatusBox type={choiceProduct.status} />
            </Box>
          </Box>

          {choiceProduct.changes.map((change, i) => {
            // TODO should use the new date field in future
            const date = choiceProduct.timestamp;
            const [oldValue, newValue] = getValuesForChangeDetail(change);
            return (
              <ChangeDetail
                key={i}
                label={getTypeOfChange(change.type_of_change)}
                date={change.updatedOn ?? date}
                oldValue={oldValue}
                newValue={newValue}
                language={change.language}
              />
            );
          })}
        </HighLightBox>
      )}
      <ChoiceGroupDetailSection
        t={Translate}
        title={choiceGroupDetailTitle}
        defaultLanguage={choiceProduct.defaultLanguage}
        languages={choiceProduct.languages || []}
        handleTitleChange={handleTitleChange}
        quantity={choiceGroupDetailQuantity}
        numberOfChoices={pickedChoices.length}
        isMinmaxDisable={false}
        isTextFieldDisable={false}
        handleMaximumChange={handleMaximumChange}
        handleMinimumChange={handleMinimumChange}
      />
      <ChoicesSection
        t={Translate}
        defaultLanguage={choiceProduct.defaultLanguage}
        languages={choiceProduct.languages || []}
        currency={choiceProduct?.currency?.code || "SGD"}
        pickedChoices={pickedChoices}
        allChoices={[]}
        pickingDisabled={true}
        isAddDisabled={true}
        isPriceDisabled={true}
        handleAddChoice={() => {}}
        handleChoiceChange={() => {}}
        handleChoicePriceChange={() => {}}
        handleDelete={() => {}}
      />
    </Modal>
  );
};

export default ChoiceGroupDetailModal;
