import * as React from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { TYPE_OF_CHANGE, TYPE_OF_CHANGE_CHOICE_GROUP } from "../../../App/common/constant";
import { codeToSymbol } from "../../utility/translation";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
const useStyles = makeStyles(styles);

const {
  TITLE_CHANGE,
  DESCRIPTION_CHANGE,
  VARIANT_UNITPRICE_CHANGE,
  VARIANT_TITLE_CHANGE,
  CATEGORY_CHANGE,
  VARIANT_CREATE,
  VARIANT_DELETE,
  PRODUCT_CREATE,
  VARIANT_CHOICE_GROUP_ADD,
  VARIANT_CHOICE_GROUP_DELETE,
} = TYPE_OF_CHANGE;

const {
  CHOICES_CREATE,
  CHOICES_DELETE,
  CHOICES_ADD,
  CHOICE_GROUP_CREATE,
  CHOICE_GROUP_QUANTITY_CHANGE,
  CHOICE_GROUP_TITLE_CHANGE,
  CHOICES_PRICE_CHANGE
} = TYPE_OF_CHANGE_CHOICE_GROUP

// will have choice create in the future
const CREATE_LIST = [VARIANT_CREATE, VARIANT_CHOICE_GROUP_ADD, CHOICE_GROUP_CREATE, CHOICES_CREATE, CHOICES_ADD];
const DELETE_LIST = [VARIANT_DELETE, VARIANT_CHOICE_GROUP_DELETE, CHOICES_DELETE];

const ChangeDetail = ({ oldValue, newValue, label, date, language }) => {
  const classes = useStyles();
  return (
    <Box p={1} color="rgba(0, 0, 0, 0.87)">
      <Box p={1} fontSize={16} fontWeight={"bold"} bgcolor="white">
        {label} {language && `[ ${codeToSymbol[language]} ]`}
        <Box style={{ float: "right" }}>
          <Box component="span" fontWeight="normal">
            Requested On{" "}
          </Box>
          {moment(parseInt(date)).format("DD MMM, YYYY")}
        </Box>
      </Box>

      {label === PRODUCT_CREATE ? (
        <></>
      ) : (
        <>
          {CREATE_LIST.includes(label) ? (
            <></>
          ) : (
            <Box
              p={1}
              style={{ textDecoration: "line-through" }}
              className={classes.changeValuesOld + " oldValue"}
            >
              {oldValue ?? "-"}
            </Box>
          )}
          {DELETE_LIST.includes(label) ? (
            <></>
          ) : (
            <Box p={1} className={"newValue " + classes.changeValuesNew}>
              {newValue ?? "-"}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

ChangeDetail.propTypes = {
  oldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOf([
    TITLE_CHANGE,
    DESCRIPTION_CHANGE,
    VARIANT_UNITPRICE_CHANGE,
    VARIANT_TITLE_CHANGE,
    CATEGORY_CHANGE,
    VARIANT_CREATE,
    VARIANT_DELETE,
    PRODUCT_CREATE,
    VARIANT_CHOICE_GROUP_ADD,
    VARIANT_CHOICE_GROUP_DELETE,
    CHOICES_CREATE,
    CHOICES_DELETE,
    CHOICES_ADD,
    CHOICE_GROUP_CREATE,
    CHOICE_GROUP_QUANTITY_CHANGE,
    CHOICE_GROUP_TITLE_CHANGE,
    CHOICES_PRICE_CHANGE
  ]).isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  language: PropTypes.string,
};

export default ChangeDetail;
