import React, { useState, useEffect } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import FileCopy from "@material-ui/icons/FileCopy";
import Box from "@material-ui/core/Box";
import { copyToClipboard } from "../../../utility/information";
import Notification from "../../../App/components/Notification";
import { tablestyles } from "./styles";
import { isClosedWholeDay } from "../../utility/specialday";

const useStyles = makeStyles(tablestyles);

const NumToDate = num => {
  switch (num) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      return "Sunday";
  }
};

const TableContent = ({
  groupedVendor,
  handleVendorCheck,
  isVendorChecked,
  setModalStatus,
  setIsEditSpecialHourModalOpen,
  setCurrentRequest,
  isProductChecked,
  handleProductCheck,
  setCurrentSpecialDay,
}) => {
  const classes = useStyles();
  const [collapse, setCollapse] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(groupedVendor)) {
      setCollapse([Object.keys(groupedVendor)[0]]);
    }
  }, [groupedVendor]);

  const handleClickRow = id => {
    if (collapse.find(list => list === id)) {
      const filtered = collapse.filter(list => {
        return list !== id;
      });
      setCollapse(filtered);
    } else {
      setCollapse([...collapse, id]);
    }
  };

  const isExpanded = id => {
    return collapse.findIndex(item => item === id) >= 0;
  };

  const getEndTime = endTime => {
    const endTimeText = endTime.substring(0, endTime.length - 3);
    return endTimeText === "23:59" ? "00:00" : endTimeText;
  };

  const renderSchedule = request => {
    if (!request.changes) {
      return <></>;
    }

    if (request.type === "exception") {
      return <></>;
    }

    return (
      <Box display="flex">
        <h4 style={{ minWidth: 200 }}>Request Changes</h4>

        {request.changes.map(change => {
          return (
            <Box
              component="span"
              p={1}
              key={`${change.weekday}_${change.start}_${change.end}`}
            >
              <Typography style={{ fontWeight: "bold" }}>
                {NumToDate(change.weekday)}{" "}
              </Typography>
              <Typography>
                {change.start.substring(0, change.start.length - 3)} -{" "}
                {getEndTime(change.end)}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  const renderSpecialDaylabel = (date, startTime, endTime) => {
    if (isClosedWholeDay(startTime, endTime)) {
      return `Closed on ${date}`;
    }

    return `Open on ${date} ( ${startTime.substring(
      0,
      startTime.length - 3,
    )} to ${getEndTime(endTime)} )`;
  };

  const renderSpecialDay = request => {
    const { change } = request;
    if (!change) {
      return <></>;
    }
    return (
      <Box display="flex">
        <h4 style={{ minWidth: 200 }}>Special Day</h4>

        <Box component="span" p={1} key={change.date}>
          <Typography style={{ fontWeight: "bold" }}>
            {moment(change.date).format("dddd")}
          </Typography>
          <Typography>
            {renderSpecialDaylabel(
              change.date,
              change.start_time,
              change.end_time,
            )}
          </Typography>
        </Box>
      </Box>
    );
  };

  const getKey = request => {
    return request.createdOn;
  };

  return (
    <Paper className={classes.tableRoot}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left"> Vendor Name</TableCell>
            <TableCell align="right" className={classes.vendorCode}>
              Vendor Code
            </TableCell>
            <TableCell align="right" className={classes.submitDate}>
              Date Submitted
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(groupedVendor).map(index => (
            <React.Fragment key={index}>
              <TableRow
                key={`${groupedVendor[index][0].vendorId}-${index}`}
                onClick={() => handleClickRow(index)}
              >
                <TableCell align="left">
                  <Checkbox
                    checked={isVendorChecked(groupedVendor[index][0].vendorId)}
                    onClick={handleVendorCheck}
                    value={groupedVendor[index][0].vendorId}
                  />
                  {groupedVendor[index][0].externalResturantName}
                </TableCell>
                <TableCell
                  align="right"
                  onClick={e => {
                    e.stopPropagation();
                    copyToClipboard(groupedVendor[index][0].vendorId);
                    setOpenNotification(true);
                  }}
                  className={classes.vendorCode}
                >
                  <Box
                    p={1}
                    display="flex"
                    flexDirection="row"
                    alignItems="end"
                  >
                    <Box flex={4} color="gray">
                      <FileCopy />
                    </Box>
                    <Box flex={1}>
                      {"  "}
                      {groupedVendor[index][0].vendorId}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="right" className={classes.submitDate}>
                  {moment(groupedVendor[index][0].modifiedOn).format(
                    "DD/MM/YYYY",
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="7" style={{ padding: 0, border: 0 }}>
                  <Collapse
                    in={isExpanded(index)}
                    timeout="auto"
                    unmountOnExit
                    data-cp="MuiCollapse-wrapper"
                  >
                    <Box
                      p={4}
                      bgcolor="#f9f9f9"
                      className="OHOH"
                      display="flex"
                      flexWrap="wrap"
                      flex="1"
                    >
                      {groupedVendor[index].map(request => (
                        <React.Fragment key={getKey(request)}>
                          <Box
                            style={{
                              marginBottom: 10,
                              width: "100%",
                            }}
                            p={1}
                            border={1}
                            display="flex"
                            borderColor="grey.300"
                            onClick={() => {
                              if (request.type === "exception") {
                                setIsEditSpecialHourModalOpen(true);
                                setCurrentSpecialDay(request);
                              } else {
                                setModalStatus(true);
                                setCurrentRequest(request);
                              }
                            }}
                          >
                            <Box style={{ marginTop: 5 }}>
                              <Checkbox
                                onClick={e =>
                                  handleProductCheck(
                                    e,
                                    `${request.vendorId}_${request.createdOn}`,
                                    request.type,
                                  )
                                }
                                checked={isProductChecked(
                                  `${request.vendorId}_${request.createdOn}`,
                                )}
                              />
                            </Box>
                            {renderSchedule(request)}
                            {renderSpecialDay(request)}
                          </Box>
                        </React.Fragment>
                      ))}
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <Notification
        variant="success"
        open={openNotification}
        handleClose={() => {
          setOpenNotification(false);
        }}
        message="Successfully copied the vendor code to clipboard"
      />
    </Paper>
  );
};

TableContent.propTypes = {
  groupedVendor: PropTypes.object.isRequired,
  handleVendorCheck: PropTypes.func.isRequired,
  isVendorChecked: PropTypes.func.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  setIsEditSpecialHourModalOpen: PropTypes.func.isRequired,
  setCurrentRequest: PropTypes.func.isRequired,
  isProductChecked: PropTypes.func.isRequired,
  handleProductCheck: PropTypes.func.isRequired,
  setCurrentSpecialDay: PropTypes.func.isRequired,
};

export default TableContent;
