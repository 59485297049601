import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { modalStyles } from "./styles";

const ModalAppBar = ({
  classes,
  onClose,
  isFormDirty,
  isSaving,
  formType = "save",
}) => {
  const getButtonText = saving => {
    if (formType === "save") {
      return saving ? "Saving" : "Save";
    }
    return saving ? "Updating" : "Update";
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: "rgb(45, 54, 69)" }}>
        <Toolbar>
          <IconButton
            onClick={onClose}
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
          >
            <KeyboardBackspace />
          </IconButton>

          <Typography
            variant="h4"
            color="inherit"
            className={classes.flex}
            style={{ fontSize: 20, flex: 1 }}
          >
            Add Holidays and Special Hours
          </Typography>

          <Button
            type="submit"
            color="primary"
            disabled={!isFormDirty || isSaving}
            variant="contained"
            data-cp="product-save-btn"
          >
            {getButtonText(isSaving)}
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

ModalAppBar.defaultProps = {
  formType: "save",
};

ModalAppBar.propTypes = {
  onClose: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  formType: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(modalStyles)(ModalAppBar);
