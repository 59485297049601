export const styles = theme => ({
  root: {
    background: props => {
      switch (props.type) {
        case "pending":
          return theme.palette.status.pending;
        case "rejected":
          return theme.palette.status.rejected;
        default:
          return theme.palette.status.success;
      }
    }
  }
});

export default styles;
