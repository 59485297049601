import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Cookies from "js-cookie";
import _ from "lodash";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import axios from "../../../App/utility/axios";
import styles from "./styles";
import TableContent from "./Table";
import config from "../../../App/config";
import Notification from "../../../App/components/Notification";
import { getCurrentCountry } from "../../../utility/information";
import AppRightBar from "../../../App/layouts/AppRightBar";
import EditSchedule from "../../components/EditSchedule";
import EditSpecialHour from "../../components/EditSpecialHour";
import ApproveButton from "../../../App/components/ApproveButton";

const useStyles = makeStyles(styles);

const transform = data => {
  return _.groupBy(data, "vendorId");
};

const Home = ({ setCustomContext, customContext }) => {
  const classes = useStyles();
  const [successNotification, setSuccessNotification] = useState({
    open: false,
    message: "",
    variant: "",
  });

  const [errorNotification, setErrorNotification] = useState({
    open: false,
    message: "",
    variant: "",
  });

  const [loading, setLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [checks, setChecks] = useState([]);
  const [checkedVendors, setCheckedVendors] = useState([]);
  const [rows, setRows] = useState([]);
  const [groupedVendor, setGroupedVendor] = useState({});
  const [isEditScheduleModalOpen, setIsEditScheduleModalOpen] = useState(false);
  const [isEditSpecialHourModalOpen, setIsEditSpecialHourModalOpen] = useState(
    false,
  );
  const [currentRequest, setCurrentRequest] = useState(null);
  const [currentSpecialDay, setCurrentSpecialDay] = useState(null);
  const { currentCountry } = customContext;

  useEffect(() => {
    Cookies.set("country", getCurrentCountry("SG"));
    if (currentCountry) {
      Cookies.set("country", currentCountry.code);
    }
    setLoading(true);

    (async function fetchData() {
      try {
        const getData = async country => {
          const openingTime = await axios(
            `${config.openingTimeApi}/api/v1/opentime/pending?countryCode=${country.code}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
            },
          );

          let exceptionTimes = await axios(
            `${config.openingTimeApi}/api/v1/exceptiontime/pending?countryCode=${country.code}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
            },
          );

          const value = openingTime.data.filter(
            d => d.progressStatus === "pending",
          );
          exceptionTimes = exceptionTimes.data.map(exceptionTime => ({
            ...exceptionTime,
            type: "exception",
          }));

          setRows([...value, ...exceptionTimes]);
          const grouped = transform([...value, ...exceptionTimes]);
          setGroupedVendor(grouped);
        };

        await getData(currentCountry, "pending");
      } catch (error) {
        console.error(error.response);
      }
      setLoading(false);
    })();
  }, [currentCountry]);

  useEffect(() => {
    setGroupedVendor({});
    setRows([]);
    setChecks([]);
    setGroupedVendor([]);
  }, [currentCountry]);

  const isProductChecked = productId => {
    return (
      checks.findIndex(check => {
        return check === productId;
      }) >= 0
    );
  };

  const isVendorChecked = id => {
    return (
      checkedVendors.findIndex(check => {
        return check === id;
      }) >= 0
    );
  };

  const saveData = async (data, type = "approved") => {
    const body = {
      ...data,
      progressStatus: type,
      changes: data.changes.map(change => {
        return {
          ...change,
          end: change.end === "00:00:00" ? "23:59:59" : change.end,
        };
      }),
    };

    // eslint-disable-next-line no-useless-concat
    return axios(
      `${config.openingTimeApi}/api/v1/opentime/${data.vendorId}/updateChangeRequest`,
      {
        method: "put",
        data: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
          "X-Global-Entity-Id": body.globalEntityId,
        },
      },
    );
  };

  const saveSpecialDay = async (data, type = "approved") => {
    const { change } = data;
    const body = {
      ...data,
      progressStatus: type,
      change: {
        ...change,
        end_time: change.end_time === "00:00:00" ? "23:59:59" : change.end_time,
      },
    };
    // eslint-disable-next-line no-useless-concat
    return axios(
      `${config.openingTimeApi}/api/v1/exceptiontime/${data.vendorId}/updateChangeRequest`,
      {
        method: "put",
        data: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
          "X-Global-Entity-Id": body.globalEntityId,
        },
      },
    );
  };

  /**
   * Function to clear checks anytime
   * 1- called from batchApprove
   * 2- called from batchReject
   */
  const clearSelectedChecks = () => {
    setChecks([]);
  };

  const handleProductCheck = (e, changeId) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    if (isProductChecked(changeId)) {
      setChecks(checks.filter(check => check !== changeId));
    } else {
      setChecks([...checks, changeId]);
    }
  };

  const handleAttachFileClicked = (vendorId = "") => {
    window.open(`/photo/menu/${vendorId}/${currentCountry.code}`);
  };

  const handleVendorCheck = e => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    const vendorId = e.target.value;

    const vendorRequests = rows.filter(row => row.vendorId === vendorId);
    const requestIds = vendorRequests.map(
      request => `${request.vendorId}_${request.createdOn}`,
    );

    if (isVendorChecked(vendorId)) {
      setCheckedVendors(checkedVendors.filter(check => check !== vendorId));
      setChecks(checks.filter(check => requestIds.indexOf(check) < 0));
    } else {
      setCheckedVendors([...checkedVendors, vendorId]);
      setChecks(_.union([...checks], requestIds));
    }
  };

  const handleSaves = async approvedRequests => {
    setIsEditScheduleModalOpen(false);

    const remainingRequests = [];
    const approvedIds = {};

    approvedRequests.forEach(request => {
      approvedIds[
        `${request.vendorId}_${request.createdOn}`
      ] = `${request.vendorId}_${request.createdOn}`;
    });

    Object.keys(groupedVendor).forEach(index => {
      groupedVendor[index].forEach(p => {
        if (!approvedIds[`${p.vendorId}_${p.createdOn}`]) {
          remainingRequests.push(p);
        }
      });
    });

    const newVendors = transform(remainingRequests);

    setGroupedVendor({
      ...newVendors,
    });

    const saveRequests = approvedRequests.map(
      request => `${request.vendorId}_${request.createdOn}`,
    );

    const newRows = rows.filter(
      row => !saveRequests.includes(`${row.vendorId}_${row.createdOn}`),
    );
    setRows(newRows);
  };

  const batchApprove = async () => {
    setApproveLoading(true);

    const savePromises = [];
    const savePromisesSpecialDays = [];

    const approvedRequests = [];
    checks.forEach(check => {
      const data = rows.find(
        row => `${row.vendorId}_${row.createdOn}` === check,
      );
      approvedRequests.push(data);
      if (data.type === "exception") {
        savePromisesSpecialDays.push(saveSpecialDay(data, "approved"));
      } else {
        savePromises.push(saveData(data, "approved"));
      }
    });

    try {
      await Promise.all(savePromises);
      await Promise.all(savePromisesSpecialDays);
      handleSaves(approvedRequests);
      setApproveLoading(false);
      clearSelectedChecks();
      setSuccessNotification({
        open: true,
        variant: "success",
        message: `Successfully approved request${
          savePromises.length > 1 ? "s" : ""
        }`,
      });
    } catch (error) {
      console.error(error);
      setApproveLoading(false);
      setErrorNotification({
        open: true,
        variant: "error",
        message: `APN-${error.status}: ${error.message}`,
      });
    }
  };

  const amendChanges = async schedules => {
    try {
      currentRequest.changes = schedules;
      setApproveLoading(true);
      await saveData(currentRequest);
      setApproveLoading(false);
      handleSaves([currentRequest]);
      setCurrentRequest(null);
      setIsEditScheduleModalOpen(false);

      setSuccessNotification({
        open: true,
        variant: "success",
        message: "Successfully approved request",
      });
    } catch (error) {
      setApproveLoading(false);
      setErrorNotification({
        open: true,
        variant: "error",
        message: `APN-${error.status}: ${error.message}`,
      });
    }
  };

  const amendSpecialDayChange = async specialDayChange => {
    try {
      currentSpecialDay.change = specialDayChange;
      setApproveLoading(true);
      await saveSpecialDay(currentSpecialDay);
      setApproveLoading(false);
      handleSaves([currentSpecialDay]);
      setCurrentSpecialDay(null);
      setIsEditScheduleModalOpen(false);

      setSuccessNotification({
        open: true,
        variant: "success",
        message: "Successfully approved request",
      });
    } catch (error) {
      console.log(error);
      setApproveLoading(false);
      setErrorNotification({
        open: true,
        variant: "error",
        message: `APN-${error.status}: ${error.message}`,
      });
    }
  };

  const showTableContent = data => {
    if (!_.isEmpty(data)) {
      return (
        <Box>
          <TableContent
            groupedVendor={data}
            handleVendorCheck={handleVendorCheck}
            handleAttachFileClicked={handleAttachFileClicked}
            isVendorChecked={isVendorChecked}
            setModalStatus={setIsEditScheduleModalOpen}
            setIsEditSpecialHourModalOpen={setIsEditSpecialHourModalOpen}
            setCurrentRequest={setCurrentRequest}
            setCurrentSpecialDay={setCurrentSpecialDay}
            handleProductCheck={handleProductCheck}
            isProductChecked={isProductChecked}
          />
        </Box>
      );
    }
    return (
      <Box bgcolor="#ffff" m={5} className={classes.image}>
        <img
          alt="logo"
          src="/assets/EmptyState.png"
          width="auto"
          height="600px"
        />
      </Box>
    );
  };

  const getButtonBox = () => {
    return (
      <Box>
        <ApproveButton
          className={classes.button}
          onClick={batchApprove}
          disabled={checks.length === 0 || approveLoading}
        >
          {approveLoading ? "Approving" : "Approve"}
        </ApproveButton>
      </Box>
    );
  };

  useEffect(() => {
    setCustomContext(prev => ({ ...prev, name: "Opening Time Requests" }));
  }, [setCustomContext]);

  const modalRooter = document.getElementById("appbar");

  return (
    <div>
      {modalRooter && (
        <AppRightBar modalRoot={modalRooter}>{getButtonBox()}</AppRightBar>
      )}

      <Container maxWidth="xl" className={classes.innerscroll}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            {loading ? (
              <Box p={5} className={classes.loader}>
                <CircularProgress />
              </Box>
            ) : (
              showTableContent(groupedVendor)
            )}
          </Grid>
        </Grid>
      </Container>

      {currentRequest && (
        <EditSchedule
          open={isEditScheduleModalOpen}
          closeEditScheduleModal={() => setIsEditScheduleModalOpen(false)}
          data={currentRequest}
          onSubmit={amendChanges}
          isSaving={approveLoading}
        />
      )}

      {currentSpecialDay && (
        <EditSpecialHour
          open={isEditSpecialHourModalOpen}
          closeEditScheduleModal={() => setIsEditSpecialHourModalOpen(false)}
          data={currentSpecialDay}
          onSubmit={amendSpecialDayChange}
          isSaving={approveLoading}
        />
      )}

      <div>
        <Notification
          variant="success"
          open={successNotification.open}
          handleClose={() => {
            setSuccessNotification({
              open: false,
              variant: "success",
              message: "",
            });
          }}
          message={successNotification.message}
        />

        <Notification
          variant="error"
          open={errorNotification.open}
          handleClose={() => {
            setErrorNotification({
              open: false,
              variant: "success",
              message: "",
            });
          }}
          message={errorNotification.message}
        />
      </div>
    </div>
  );
};

Home.propTypes = {
  setCustomContext: PropTypes.func.isRequired,
  customContext: PropTypes.shape({
    currentCountry: PropTypes.object,
  }).isRequired,
};

export default Home;
