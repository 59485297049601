import { COUNTRY_LIST } from "../../App/common/constant";
import Cookies from "js-cookie";

export function getCurrentCountry(countryCode) {
  const country = COUNTRY_LIST[countryCode];
  if (!countryCode || !country) {
    countryCode = "SG";
    Cookies.set("country", "SG");
  }

  return COUNTRY_LIST[countryCode];
}

export function getAgentEmail(authorizationToken) {
  const authorizationTokenPayload = JSON.parse(
    atob(authorizationToken.split(".")[1]),
  );
  return authorizationTokenPayload.data.email;
}
