import axios from "axios";
import Cookies from "js-cookie";
import { ERROR_LIST } from "../common/constant";

const getErrorRes = err => {
  //Setting error obj based on status and by default setting to 500
  const error =
    (err.response && ERROR_LIST[err.response.status]) || ERROR_LIST[500];

  // Setting the error trace to error obj
  if (err.response && err.response.data.errors) {
    error.detail = JSON.stringify(err.response.data.errors);
  } else {
    error.detail = (err.response && err.response.data.message) || "";
  }

  //log out the error in console
  console.error("Error:", error, err);

  return error;
};
const instance = axios.create();

// Add a response interceptor
instance.interceptors.response.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      Cookies.remove("token");
      window.location.href = "/login";
    }
    return Promise.reject(getErrorRes(error));
  },
);

export default instance;
