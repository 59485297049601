export const isClosedWholeDay = (startTime, endTime) => {
  return (
    startTime === "00:00:00" &&
    (endTime === "00:00:00" || endTime === "23:59:59")
  );
};

export const mapsIntToDate = day => {
  switch (day) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thur";
    case 5:
      return "Fri";
    default:
      return "Sat";
  }
};
