import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { List, ListItem, ListItemText } from "@material-ui/core";
import styles from "./styles";

const SideBar = ({ classes, className, sideBar }) => {
  const rootClassName = classNames(classes.root, className);
  const history = useHistory();
  const location = useLocation();

  return (
    <nav className={rootClassName}>
      <List component="div" disablePadding style={{ display: "flex" }}>
        {sideBar.map((page, index) => (
          <ListItem
            key={index}
            className={
              location.pathname === page.path
                ? classes.activeListItem
                : classes.ListItem
            }
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(page.path);
            }}
          >
            <ListItemText
              key={index}
              classes={{ primary: classes.listItemText }}
              primary={page.label}
            />
          </ListItem>
        ))}
      </List>
    </nav>
  );
};

SideBar.propTypes = {
  open: PropTypes.bool,
};

export default withStyles(styles)(SideBar);
