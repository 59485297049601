import _ from "lodash";
import { COUNTRY_LIST } from "../App/common/constant";
import Cookies from "js-cookie";

export function getItemStatusToEnum(status) {
  switch (status) {
    case "approved":
      return 0;
    case "rejected":
      return 1;
    case "pending":
      return 2;
    default:
      return 0;
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getCurrentCountry(countryCode) {
  let country = COUNTRY_LIST[countryCode];
  if (!countryCode || !country) {
    countryCode = "SG";
    Cookies.set("country", "SG");
  }

  return COUNTRY_LIST[countryCode];
}

export function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed !", ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

export function getDefaultLanguage(languages) {
  let currentLanguage;
  if (languages) {
    let languageKeys = Object.keys(languages);
    let english = languageKeys.filter(language => {
      return _.startsWith(language, "en_");
    });
    english && english.length > 0
      ? (currentLanguage = english[0])
      : (currentLanguage = languageKeys[0]);
  }
  return currentLanguage;
}
