const translatedString = {
  "menu_management_pandora.choice_group_details": "Choice Group Details",
  "menu_management_pandora.topping_template_name": "Name",
  "menu_management_pandora.prompt_to_customer_help":
    "Example: Choice of Sides, Choice of Extras",
  "menu_management_pandora.minimum_number_of_choices":
    "Minimum Number of Choices",
  "menu_management_pandora.maximum_number_of_choices":
    "Maximum Number of Choices",
  "menu_management_pandora.minimum_number_of_choices_help":
    "Enter '0' if this group is optional for your customers",
  "menu_management_pandora.maximum_number_of_choices_help":
    "Enter '1' if your customers can only choose 1",
  "menu_management_pandora.edit_topping_template": "Edit choice group",
  "menu_management_pandora.save": "Save",
  "menu_management_pandora.toppings": "Choices",
  "menu_management_pandora.add_a_topping": "Add a Choice",
  "menu_management_pandora.choices_section_help_text":
    "Add choices for your customer to pick from",
  "menu_management_pandora.choice_name": "Choice Name",
  "menu_management_pandora.price": "Price",
  "menu_management_pandora.optional": "optional",
  "menu_management_pandora.choice_groups": "Choice Groups",
  "menu_management_pandora.choice_groups_eg":
    "Example: Topping, Suger Level, Choice of Side",
  "menu_management_pandora.prompt_to_customer": "Name",
  "menu_management_pandora.choices": "Choices",
  "menu_management_pandora.number_of_choice": "Number of Choices",
};

const Translate = key => {
  return translatedString[key] || key;
};

export default Translate;
