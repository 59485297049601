const styles = () => ({
  sectionWrapper: {
    borderRadius: 5,
    border: "1px solid #e5e5e5",
    "@media (max-width: 500px)": {
      border: 0,
    },
  },
  sectionHeader: {
    background: "#f5f5f5",
    padding: "26px 23px 22px",
    display: "flex",
  },
  sectionHeaderSpan: {
    fontSize: "18px",
    fontWeight: 600,
    color: "rgba(0, 0, 0, 0.87)",
    flex: 1,
  },
  sectionContent: {
    padding: "26px 23px 22px",
    "@media (max-width: 500px)": {
      padding: "16px 16px 24px",
    },
  },
});

export default styles;
