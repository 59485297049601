export const TYPE_OF_CHANGE = {
  VARIANT_UNITPRICE_CHANGE: "VariantUnitPriceChange",
  DESCRIPTION_CHANGE: "DescriptionChange",
  TITLE_CHANGE: "TitleChange",
  VARIANT_TITLE_CHANGE: "VariantTitleChange",
  CATEGORY_CHANGE: "CategoryChange",
  VARIANT_CREATE: "VariantCreate",
  VARIANT_DELETE: "VariantDelete",
  PRODUCT_CREATE: "ProductCreate",
  VARIANT_CHOICE_GROUP_ADD: "VariantChoiceGroupAdd",
  VARIANT_CHOICE_GROUP_DELETE: "VariantChoiceGroupDelete",
};

export const TYPE_OF_CHANGE_CHOICE_GROUP = {
  CHOICE_GROUP_CREATE: "ChoiceGroupCreate",
  CHOICE_GROUP_QUANTITY_CHANGE: "ChoiceGroupQuantityChange",
  CHOICE_GROUP_TITLE_CHANGE: "ChoiceGroupTitleChange",
  CHOICES_ADD: "ChoicesAdd",
  CHOICES_DELETE: "ChoicesDelete",
  CHOICES_CREATE: "ChoicesCreate",
  CHOICES_PRICE_CHANGE: "ChoicesPriceChange"
};
