//@flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import axios from "../../../App/utility/axios";
import Grid from "@material-ui/core/Grid";
import TableContent from "./Table";
import config from "../../../App/config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import styles from "./styles";
import Container from "@material-ui/core/Container";
import Cookies from "js-cookie";
import jszip from "jszip";
import fileSaver from "file-saver";
import { getCurrentCountry } from "../../../utility/information";
import ApproveButton from "../../../App/components/ApproveButton";
import RejectButton from "../../../App/components/RejectButton";
import DownloadButton from "../../../App/components/DownloadButton";
import AppRightBar from "../../../App/layouts/AppRightBar";
import Notification from "../../../App/components/Notification";
import UploadPhotoModal from "../../components/UploadPhotoModal";
import UploadButton from "../../../App/components/UploadButton";
import RejectModal from "../../../App/components/RejectModal";
import { useCallback } from "react";
import { getAgentEmail } from "../../../App/utility";

const useStyles = makeStyles(styles);
export const ManagePhoto = ({ setCustomContext, customContext }) => {
  const classes = useStyles();
  const [groupedPhoto, setGroupedPhoto] = useState([]);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [downloading, setDownloadInProgress] = useState(false);
  const [checks, setChecks] = useState([]);
  const [checkedAllPhoto, setCheckedAllPhoto] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const currentCountry = customContext.currentCountry;
  const [successNotification, setSuccessNotification] = useState({
    open: false,
    message: "",
    variant: "",
  });
  const [errorNotification, setErrorNotification] = useState({
    open: false,
    message: "",
    variant: "",
  });
  const [agentEmail, setAgentEmail] = useState("");

  useEffect(() => {
    setAgentEmail(getAgentEmail(Cookies.get("token")));
  }, []);

  const [uploadPhotoLoading, setUploadPhotoLoading] = useState(false);
  const [isUploadPhotoModalOpen, setisUploadPhotoModalOpen] = useState(false);

  const getButtonBox = () => {
    return (
      <Box>
        <Box className={classes.defaultOptions}>
          <ApproveButton
            className={classes.button}
            onClick={batchApprove}
            disabled={checks.length === 0 || approveLoading}
          >
            {approveLoading ? "Approving" : "Approve"}
          </ApproveButton>
          <RejectButton
            className={classes.button}
            disabled={checks.length === 0}
            onClick={() => setIsRejectModalOpen(true)}
          >
            {"Reject"}
          </RejectButton>
        </Box>
        <Box className={classes.moreOptions}>
          <DownloadButton
            className={classes.button}
            onClick={batchDownload}
            disabled={checks.length === 0 || downloading}
          >
            {downloading ? "Downloading" : "Download"}
          </DownloadButton>
          <UploadButton
            className={classes.button}
            onClick={() => setisUploadPhotoModalOpen(true)}
            variant="outlined"
          >
            Upload
          </UploadButton>
        </Box>
        <Box display="inline-block">{checks.length} selected</Box>
      </Box>
    );
  };

  useEffect(() => {
    currentCountry
      ? Cookies.set("country", currentCountry.code)
      : Cookies.set("country", getCurrentCountry("SG"));
    setLoading(true);
    (async function() {
      try {
        const getData = async (country, status) => {
          let dishPhoto = await axios(
            config.dishPhotosApi +
              "/api/v1/dishphoto/pending?countryCode=" +
              country.code,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
            },
          );
          let value = dishPhoto.data
            .filter(d => d.progressStatus === "pending")
            .sort(function(a, b) {
              return a.modifiedOn - b.modifiedOn;
            });

          setGroupedPhoto(value);
        };
        await getData(currentCountry, "pending");
      } catch (error) {
        console.error(error.response);
      }
      setLoading(false);
    })();
  }, [currentCountry]);

  useEffect(() => {
    setGroupedPhoto([]);
    setChecks([]);
    setCheckedAllPhoto(false);
  }, [currentCountry]);

  /**
   * Function to clear checks anytime
   * 1- called from batchApprove
   */
  const _clearSelectedChecks = () => {
    setChecks([]);
    setCheckedAllPhoto(false);
  };

  const handleSaves = useCallback(
    async approvedRequests => {
      let remainingRequests = [];
      let approvedIds = {};
      approvedRequests.forEach(request => {
        const photoId = getPhotoId(request.vendorId, request.productId);
        approvedIds[photoId] = photoId;
      });

      groupedPhoto.forEach(gp => {
        if (!approvedIds[getPhotoId(gp.vendorId, gp.productId)]) {
          remainingRequests.push(gp);
        }
      });

      setGroupedPhoto([...remainingRequests]);
    },
    [groupedPhoto],
  );

  const handleReject = useCallback(
    async reasons => {
      setRejectLoading(true);
      const rejectPromises = [];
      const rejectedRequests = [];

      checks.forEach(check => {
        const data = groupedPhoto.find(
          gp => getPhotoId(gp.vendorId, gp.productId) === check,
        );
        data.change.rejectReasons = reasons;
        rejectedRequests.push(data);
        rejectPromises.push(saveData(data, "rejected"));
      });

      try {
        await Promise.all(rejectPromises);
        handleSaves(rejectedRequests);
        setRejectLoading(false);
        setIsRejectModalOpen(false);
        _clearSelectedChecks();
        setSuccessNotification({
          open: true,
          variant: "success",
          message: `Successfully rejected request${
            rejectPromises.length > 1 ? "s" : ""
          }`,
        });
      } catch (error) {
        console.error(error);
        setRejectLoading(false);
        setErrorNotification({
          open: true,
          variant: "error",
          message: `APN-${error.status}: ${error.message}`,
        });
      }
    },
    [checks, groupedPhoto, handleSaves],
  );

  const batchApprove = async () => {
    setApproveLoading(true);

    let savePromises = [];
    let approvedRequests = [];

    checks.forEach(check => {
      const data = groupedPhoto.find(
        gp => getPhotoId(gp.vendorId, gp.productId) === check,
      );
      approvedRequests.push(data);

      savePromises.push(saveData(data, "approved"));
    });

    try {
      await Promise.all(savePromises);
      handleSaves(approvedRequests);
      setApproveLoading(false);
      _clearSelectedChecks();
      setSuccessNotification({
        open: true,
        variant: "success",
        message: `Successfully approved request${
          savePromises.length > 1 ? "s" : ""
        }`,
      });
    } catch (error) {
      console.error(error);
      setApproveLoading(false);
      setErrorNotification({
        open: true,
        variant: "error",
        message: `APN-${error.status}: ${error.message}`,
      });
    }
  };

  const saveData = async (data, type = "approved") => {
    //check if the dish photo request is for a new item and change the status to pending-approved
    if (data.productId.startsWith("NEW") && type === "approved") {
      type = "pending-approved";
    }

    const body = {
      ...data,
      progressStatus: type,
    };

    return axios(
      config.dishPhotosApi +
        `/api/v1/dishphoto/${data.vendorId}/updateChangeRequest`,
      {
        method: "put",
        data: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("token"),
          "X-Global-Entity-Id": body.globalEntityId,
        },
      },
    );
  };

  const getPhotoId = (vendorId, productId) => {
    return vendorId + "|" + productId;
  };

  const handlePhotoCheck = (e, vendorId, productId) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    if (isPhotoChecked(vendorId, productId)) {
      setChecks(
        checks.filter(check => check !== getPhotoId(vendorId, productId)),
      );
      setCheckedAllPhoto(false);
    } else {
      setChecks([...checks, getPhotoId(vendorId, productId)]);
    }
  };

  const isPhotoChecked = (vendorId, productId) => {
    return (
      checks.findIndex(check => {
        return check === getPhotoId(vendorId, productId);
      }) >= 0
    );
  };

  const handleAllPhotoCheck = e => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    if (isAllPhotoChecked()) {
      setChecks([]);
      setCheckedAllPhoto(false);
    } else {
      setChecks(
        Array.from(groupedPhoto, gp => getPhotoId(gp.vendorId, gp.productId)),
      );
      setCheckedAllPhoto(true);
    }
  };

  const isAllPhotoChecked = () => {
    return checkedAllPhoto;
  };

  const batchDownload = async () => {
    setDownloadInProgress(true);

    const result = groupedPhoto.filter(
      gp => checks.indexOf(getPhotoId(gp.vendorId, gp.productId)) > -1,
    );

    const zip = new jszip();
    const downloadImage = async uri => {
      const response = await fetch(uri, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: {
          Origin: window.location.origin,
        },
      });
      const buffer = await response.arrayBuffer();
      return buffer;
    };

    const imageDownloads = result.map(p => [
      p.change.location,
      downloadImage(p.change.location),
    ]);

    const downloads = await Promise.all(imageDownloads);
    downloads.forEach(([fileLocation, binaryContent]) => {
      let filename = fileLocation.substring(fileLocation.lastIndexOf("/") + 1);
      let decodedfilename = decodeURIComponent(filename).replace(
        "uploads/",
        "uploads_",
      );
      zip.file(decodedfilename, binaryContent, { binary: true });
    });

    const content = await zip.generateAsync({ type: "blob" });
    await fileSaver.saveAs(content, "download_dish_photos.zip");
    setDownloadInProgress(false);
    _clearSelectedChecks();
  };

  const batchUpload = async files => {
    try {
      setUploadPhotoLoading(true);

      const response = await axios(
        `${config.dishPhotosApi}/api/v1/dishphoto/getUploadDetailsInternal`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Cookies.get("token"),
          },
        },
      );

      const {
        awsAccessKeyId,
        bucketUrl,
        policyB64,
        signature,
      } = await response.data;

      const URL = bucketUrl;
      const uploadPromise = files.map(file => {
        let bodyFormData = new FormData();
        let filename = file.name.replace("uploads_", "");
        bodyFormData.append("Content-Type", "image/jpg");
        bodyFormData.append("AWSAccessKeyId", awsAccessKeyId);
        bodyFormData.append("key", `uploads/${filename}`);
        bodyFormData.append("acl", "public-read");
        bodyFormData.append("success_action_status", "201");
        bodyFormData.append("policy", policyB64);
        bodyFormData.append("signature", signature);
        // file must be the last
        bodyFormData.append("file", file);
        return axios.post(URL, bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      });

      await Promise.all(uploadPromise);

      let updateChangePromise = [];
      let approvedIds = [];

      const Ids = files
        .map(file => file.name)
        .map(name => {
          const data = name.split("_");

          // Due to the name of New product id
          // v3lv_NEW_1234, we need to append 3 index
          // to get actual id
          const productId =
            data[2] === "NEW" ? `${data[2]}_${data[3]}` : data[2];
          return `${data[1]}|${productId}`;
        });

      Ids.forEach(id => {
        const data = groupedPhoto.find(gp => {
          const found = getPhotoId(gp.vendorId, gp.productId) === id;
          return found;
        });

        if (data) {
          approvedIds.push(id);
          updateChangePromise.push(saveData(data, "pending"));
        }
      });

      await Promise.all(updateChangePromise);
      const time = Date.now();

      const newGroupedPhoto = groupedPhoto.map(gp => {
        if (approvedIds.includes(getPhotoId(gp.vendorId, gp.productId))) {
          return { ...gp, agentEmail, modifiedOn: time };
        }
        return gp;
      });

      setGroupedPhoto(newGroupedPhoto);
      setUploadPhotoLoading(false);
      setisUploadPhotoModalOpen(false);
      setSuccessNotification({
        open: true,
        variant: "success",
        message: `Successfully uploaded photo${files.length > 1 ? "s" : ""}`,
      });
    } catch (error) {
      console.error(error, "Dish photo upload error");
      setErrorNotification({
        open: true,
        variant: "error",
        message: `APN-${error.status}: ${error.message}`,
      });
    }
  };

  const showTableContent = groupedPhoto => {
    if (!_.isEmpty(groupedPhoto)) {
      return (
        <Box>
          <TableContent
            groupedPhoto={groupedPhoto}
            handlePhotoCheck={handlePhotoCheck}
            isPhotoChecked={isPhotoChecked}
            handleAllPhotoCheck={handleAllPhotoCheck}
            isAllPhotoChecked={isAllPhotoChecked}
          />
        </Box>
      );
    } else {
      return (
        <Box bgcolor="#ffff" m={5} className={classes.image}>
          <img
            alt="logo"
            src="/assets/EmptyState.png"
            width="auto"
            height="600px"
          />
        </Box>
      );
    }
  };
  const modalRooter = document.getElementById("appbar");

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {modalRooter && (
        <AppRightBar modalRoot={modalRooter}>{getButtonBox()}</AppRightBar>
      )}
      <Container maxWidth="xl" className={classes.innerscroll}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            {loading ? (
              <Box p={5} className={classes.loader}>
                <CircularProgress />
              </Box>
            ) : (
              showTableContent(groupedPhoto)
            )}
          </Grid>
        </Grid>
      </Container>
      <div>
        <Notification
          variant={"success"}
          open={successNotification.open}
          handleClose={() => {
            setSuccessNotification({
              open: false,
              variant: "success",
              message: "",
            });
          }}
          message={successNotification.message}
        />

        <Notification
          variant={"error"}
          open={errorNotification.open}
          handleClose={() => {
            setErrorNotification({
              open: false,
              variant: "success",
              message: "",
            });
          }}
          message={errorNotification.message}
        />

        <UploadPhotoModal
          loading={uploadPhotoLoading}
          batchUpload={batchUpload}
          open={isUploadPhotoModalOpen}
          handleClose={() => setisUploadPhotoModalOpen(false)}
        />

        <RejectModal
          loading={rejectLoading}
          setIsRejectModalOpen={setIsRejectModalOpen}
          isRejectModalOpen={isRejectModalOpen}
          onSubmit={handleReject}
          header="Choose Reject Reason"
          subheader="Are you sure there is nothing you can do to approve this item? This
          action is permanent. The vendor will see the rejection reason you
          choose."
        />
      </div>
    </div>
  );
};

export default ManagePhoto;
