import React from "react";
import theme from "../theme";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { homestyles } from "./styles";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { Typography } from "@material-ui/core";
import { ReactComponent as FOODPANDALOGO } from "../assets/foodpanda-small.svg";
import NextSideBar from "./NextSideBar";
import { COUNTRY_LIST } from "../../App/common/constant";
import { getCurrentCountry } from "../utility";
import Cookies from "js-cookie";
import CountryFilter from "../../App/components/CountryFilter";
import Box from "@material-ui/core/Box";
import { useEffect } from "react";
import Popover from "../../App/components/Popover";
import { useHistory } from "react-router-dom";
import SvgIcon from "@material-ui/core/SvgIcon";

const useStyles = makeStyles(homestyles);

function App({ children, sideBar = [], setCustomContext }) {
  const history = useHistory();

  const [currentCountry, setCountry] = React.useState(
    getCurrentCountry(Cookies.get("country")),
  );

  const handleFilterClick = value => {
    setCountry(COUNTRY_LIST[value]);
  };

  const handleLogout = () => {
    Cookies.remove("token");
    history.push("/login");
  };

  useEffect(() => {
    setCustomContext(prev => ({
      ...prev,
      currentCountry: currentCountry,
    }));
  }, [currentCountry, setCustomContext]);

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root} style={{ fontFamily: "Roboto" }}>
        <div className={classes.content}>
          <AppBar position="static" color="default" className={classes.appBar}>
            <div className={classes.appBarRoot}>
              <div className={classes.appBarLeft}>
                <div style={{ width: 40 }}>
                  <div className={classes.logoCircleBigWrapper}>
                    <FOODPANDALOGO />
                  </div>
                </div>
                <Typography
                  variant="h5"
                  style={{ marginLeft: 10, color: "white", fontWeight: "bold" }}
                >
                  Vendor Content QC
                </Typography>
                <Box ml={8}>
                  <CountryFilter
                    countryList={COUNTRY_LIST}
                    handleFilterClick={handleFilterClick}
                    currentCountry={currentCountry.code}
                  />
                </Box>
              </div>
              <div className={classes.appBarRight}>
                <Box mr={8}>
                  <Typography
                    variant="h6"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    Pending Requests
                  </Typography>
                  <Box bgcolor="primary.main" height={4}></Box>
                </Box>

                <Popover
                  placeholder={
                    <SvgIcon className={classes.userIcon}>
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                      <g>
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83z"></path>
                        <path d="M12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"></path>
                      </g>
                    </SvgIcon>
                  }
                >
                  <Box width={200} fontWeight="bold">
                    <Box style={{ cursor: "pointer" }} onClick={handleLogout}>
                      Logout
                    </Box>
                  </Box>
                </Popover>
              </div>
            </div>
            <NextSideBar sideBar={sideBar} />
          </AppBar>

          {children}

          <div className={classes.actionBarContainer} style={{ zIndex: 999 }}>
            <div id="appbar" className={classes.actionBar}></div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
