import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import _ from "lodash";
import {
  TYPE_OF_CHANGE,
  TYPE_OF_CHANGE_CHOICE_GROUP,
} from "../../App/common/constant";

const {
  TITLE_CHANGE,
  DESCRIPTION_CHANGE,
  VARIANT_UNITPRICE_CHANGE,
  VARIANT_TITLE_CHANGE,
  CATEGORY_CHANGE,
  VARIANT_CREATE,
  PRODUCT_CREATE,
  VARIANT_CHOICE_GROUP_ADD,
  VARIANT_CHOICE_GROUP_DELETE,
} = TYPE_OF_CHANGE;

const {
  CHOICE_GROUP_TITLE_CHANGE,
  CHOICE_GROUP_QUANTITY_CHANGE,
  CHOICE_GROUP_CREATE,
  CHOICES_ADD,
  CHOICES_DELETE,
  CHOICES_CREATE,
  CHOICES_PRICE_CHANGE,
} = TYPE_OF_CHANGE_CHOICE_GROUP;

// internal usage. export is just for testing
export function getChangedItems({ changes = [], keys = [] }) {
  return changes.filter(change => {
    // Skip the loop if check only one type of change
    if (keys.length === 1) {
      return keys[0] === change.type_of_change;
    }
    return keys.includes(change.type_of_change);
  });
}

export function withChangesGroupByTypeOfChange(changesGroupByTypeOfChange) {
  return function(type_of_change) {
    return _.get(changesGroupByTypeOfChange, `${type_of_change}`, []);
  };
}

export function getTitleChangeObject(product) {
  // Spread to new object to prevent side effect
  const title = {
    ...(get(product, "changeInfo.product.title") ||
      //  PRODUCT_CREATE
      get(product, "changeInfo.changes[0].title")),
  };

  product.changeInfo.changes
    .filter(change => {
      return change.type_of_change === TITLE_CHANGE;
    })
    .forEach(titleChange => {
      title[titleChange.language] = titleChange.new_value;
    });

  return title;
}

export function getDescriptionChangeObject(product) {
  const description =
    get(product, "changeInfo.product.description") ||
    // PRODUCT_CREATE
    get(product, "changeInfo.changes[0].description");

  product.changeInfo.changes
    .filter(change => {
      return change.type_of_change === DESCRIPTION_CHANGE;
    })
    .forEach(descriptionChange => {
      description[descriptionChange.language] = descriptionChange.new_value;
    });

  return description;
}

export function getCategoryChangeObject(product) {
  const category =
    get(product, "changeInfo.product.category.id") ||
    // PRODUCT_CREATE
    get(product, "changeInfo.changes[0].category");

  if (!product.changeInfo) {
    return category;
  }

  const categoryChange = getChangedItems({
    changes: product.changeInfo.changes,
    keys: [CATEGORY_CHANGE],
  });

  // Other type of changes, eg: Prodcut Create, Title Change
  if (categoryChange.length === 0) {
    return category;
  }
  return _.head(categoryChange).new_value;
}

export function getVariantsFromProduct(product) {
  return get(product, "product.variants", []);
}

function hasValueChange(...typeOfChange) {
  return function(product) {
    if (!product.changeInfo) {
      return false;
    }

    return product.changeInfo.changes.some(change => {
      return typeOfChange.includes(change.type_of_change);
    });
  };
}

export const hasTitleChange = hasValueChange(TITLE_CHANGE, PRODUCT_CREATE);
export const hasDescriptionChange = hasValueChange(
  DESCRIPTION_CHANGE,
  PRODUCT_CREATE,
);
export const hasCategoryChange = hasValueChange(
  CATEGORY_CHANGE,
  PRODUCT_CREATE,
);

export function getCompulsoryProps() {
  return {
    id: `${Math.floor(Math.random() * Math.floor(1000000))}`,
    updatedOn: `${Date.now()}`,
    effectiveOn: `${Date.now()}`,
  };
}

export function getVariantsChange(product) {
  // may undefined, to prevent side effect
  const existingVariants = product?.variants?.length
    ? product.variants.slice()
    : null;
  // to prevent side effect
  const createdVariants = [
    ...get(product, "changeInfo.changes[0].variants", []),
  ];
  let variantsPrevious = existingVariants || createdVariants;
  const firstTypeOfChange = get(
    product,
    "changeInfo.changes[0].type_of_change",
  );

  if (!product.changeInfo) {
    return variantsPrevious;
  }

  if (firstTypeOfChange === PRODUCT_CREATE) {
    return variantsPrevious.map(v => ({
      ...v,
      isTitleChanged: true,
      isPriceChanged: true,
    }));
  }

  const changes = getChangedItems({
    changes: product.changeInfo.changes,
    keys: [VARIANT_UNITPRICE_CHANGE, VARIANT_TITLE_CHANGE],
  });

  const createdChanges = getChangedItems({
    changes: product.changeInfo.changes,
    keys: [VARIANT_CREATE],
  }).map(change => {
    change.isTitleChanged = true;
    change.isPriceChanged = true;
    return change;
  });
  if (!changes.length && !createdChanges.length) {
    return variantsPrevious;
  }

  // if changes exits, mapping change variant to have isPriceChanged || isTitleChanged flag
  variantsPrevious = variantsPrevious.map(variant => {
    const newChanges = changes.filter(
      change => change.variant_id === variant.id,
    );
    if (!newChanges.length) return { ...variant, isChanged: false };

    let newVariant = {
      ...variant,
    };

    newChanges.forEach(newChange => {
      if (newChange.type_of_change === VARIANT_TITLE_CHANGE) {
        newVariant.title[newChange.language] = newChange.new_value;
        newVariant.isTitleChanged = true;
      }

      if (newChange.type_of_change === VARIANT_UNITPRICE_CHANGE) {
        newVariant.unitPrice = newChange.new_value
          ? +newChange.new_value
          : newChange.old_value;
        newVariant.isPriceChanged = true;
      }
    });

    return newVariant;
  });
  variantsPrevious.push(...createdChanges);
  return variantsPrevious;
}

export const getVariantChoiceGroupAssociationsChange = product => {
  let association = {};

  product.variants.forEach(variant => {
    association[variant.id] = {
      addedChoiceGroups: {},
      deletedChoiceGroups: {},
    };
  });

  if (!product.changeInfo || product.changeInfo.status === "approved") {
    return association;
  }

  const added = getChangedItems({
    changes: product.changeInfo.changes,
    keys: [VARIANT_CHOICE_GROUP_ADD],
  });
  const deleted = getChangedItems({
    changes: product.changeInfo.changes,
    keys: [VARIANT_CHOICE_GROUP_DELETE],
  });

  added.forEach(change => {
    association[change.variant_id].addedChoiceGroups[
      change.choice_group_id
    ] = true;
  });

  deleted.forEach(change => {
    association[change.variant_id].deletedChoiceGroups[
      change.choice_group_id
    ] = true;
  });

  return association;
};
export const getCategoryObject = (categories, value) => {
  const category = _.filter(categories, category => {
    return category.id === value;
  });
  return category[0];
};

export function getChoiceGroupObject(choiceGroups, choiceGroupId) {
  // in case the choiceGroups not fetch yet
  return (
    choiceGroups.find(choiceGroup => choiceGroup.id === choiceGroupId) ||
    choiceGroupId
  );
}

export function getChoiceGroupTitleChange(choiceGroupPayload) {
  const title = {
    ...(get(choiceGroupPayload, "choice_group.title") ||
      // Choice group create
      get(choiceGroupPayload, "changes[0].title")),
  };

  if (!choiceGroupPayload?.changes?.length) return title;

  choiceGroupPayload.changes.forEach(change => {
    if (change.type_of_change === CHOICE_GROUP_TITLE_CHANGE) {
      title[change.language] = change.new_value;
    }
  });

  return title;
}

export function getChoiceGroupQuantityChange(choiceGroupPayload) {
  const quantity = {
    ...(get(choiceGroupPayload, "choice_group.quantity") ||
      // Choice Group Create
      get(choiceGroupPayload, "changes[0].quantity")),
  };

  if (!choiceGroupPayload?.changes?.length) return quantity;

  choiceGroupPayload.changes.forEach(change => {
    if (change.type_of_change === CHOICE_GROUP_QUANTITY_CHANGE) {
      quantity[change.minMax] = change.new_value;
    }
  });

  return quantity;
}

export function getChoiceGroupPickedChoices(choiceGroupPayload) {
  // extract data when is creating choice group
  const isCreatingChoiceGroup =
    get(choiceGroupPayload, "changes[0].type_of_change") ===
    CHOICE_GROUP_CREATE;
  let newAddedChoices = [];
  let newCreatedChoices = [];
  if (isCreatingChoiceGroup) {
    newAddedChoices = get(choiceGroupPayload, "changes[0].choices", []);
    newCreatedChoices = get(choiceGroupPayload, "changes[0].newChoices", []);
  }

  const origianlChoices = get(choiceGroupPayload, "choice_group.choices", []);
  // newCreatedChoices(choice create) no need to do for loop
  // so is pushed after the loop, instead of spread into choicesWithId
  const choicesWithId = [...newAddedChoices, ...origianlChoices];
  const choicesById = keyBy(choicesWithId, "id");

  if (!choiceGroupPayload?.changes?.length) return origianlChoices;

  choiceGroupPayload.changes.forEach(change => {
    // We are not adding CHOICES_DELETE logic here
    // is because Agents need to check the choices again before delete
    if (change.type_of_change === CHOICES_ADD) {
      choicesById[change.id] = change;
    }

    if (change.type_of_change === CHOICES_CREATE) {
      newCreatedChoices.push(change);
    }

    if (change.type_of_change === CHOICES_PRICE_CHANGE) {
      choicesById[change.id].price = change.new_value;
    }
  });

  // Merging the choices and mapping to structure that components need
  const allChoices = [...Object.values(choicesById), ...newCreatedChoices].map(
    choice => ({
      choice,
      price: choice.price,
    }),
  );

  return allChoices;
}
