import { createStyles } from "@material-ui/core/styles";

const styles = () =>
  createStyles({
    bodyContainer: {
      background: "white",
      minWidth: 700,
      maxWidth: 700,
      height: 2000,
      maxHeight: "100%",
      float: "right",
      overflow: "scroll",
      "@media (max-width: 700)": {
        width: "100",
        minWidth: "100%",
      },
    },
    root: {
      flexGrow: 1,
    },
    flex: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
  });

export const selectStyles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

export const modalStyles = () => ({
  root: {
    float: "right",
    "@media (max-width: 700px)": {
      width: "100%",
      minWidth: "100%",
    },
  },
});

export const productSectionStyles = () => ({
  sectionWrapper: {
    borderRadius: 5,
    border: "1px solid #e5e5e5",
    "@media (max-width: 500px)": {
      border: 0,
    },
  },
  sectionHeader: {
    background: "#f5f5f5",
    padding: "26px 23px 22px",
    display: "flex",
  },
  sectionHeaderSpan: {
    fontSize: "18px",
    fontWeight: 600,
    color: "rgba(0, 0, 0, 0.87)",
    flex: 1,
  },
  sectionContent: {
    padding: "26px 23px 22px",
    "@media (max-width: 500px)": {
      padding: "16px 16px 24px",
    },
  },
});

export default styles;
