import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { tablestyles } from "./styles";
import {
  getDefaultLanguage,
  capitalizeFirstLetter,
} from "../../../utility/information";

const useStyles = makeStyles(tablestyles);

const getUploadedBy = (classes, vendorName, agentEmail) => {
  let className;
  let typo;
  if (agentEmail) {
    className = classes.agentEmail;
    typo = agentEmail;
  } else {
    className = classes.vendorName;
    typo = vendorName;
  }
  return <Typography className={className}>{typo}</Typography>;
};
const TableContent = ({
  groupedPhoto,
  handlePhotoCheck,
  isPhotoChecked,
  handleAllPhotoCheck,
  isAllPhotoChecked,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.tableRoot}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.checkBoxColumn}>
              <Checkbox
                checked={isAllPhotoChecked()}
                onClick={handleAllPhotoCheck}
                value={true}
                data-cp="SelectAll"
              >
                >
              </Checkbox>
            </TableCell>
            <TableCell align="left" className={classes.photoCell}>
              Select All
            </TableCell>
            <TableCell align="left"> </TableCell>
            <TableCell align="left">Uploaded By</TableCell>
            <TableCell align="right">Date Submitted</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedPhoto.map(photo => (
            <React.Fragment key={`${photo.vendorId}-${photo.productId}`}>
              <TableRow
                key={`${photo.vendorId}-${photo.productId}`}
                //onClick={() => handleClickRow(index)}
              >
                <TableCell className={classes.checkBoxColumn}>
                  <Checkbox
                    onClick={e =>
                      handlePhotoCheck(e, photo.vendorId, photo.productId)
                    }
                    checked={isPhotoChecked(photo.vendorId, photo.productId)}
                  />
                </TableCell>
                <TableCell align="left" className={classes.photoCell}>
                  <div>
                    <img
                      alt="Dish"
                      src={photo.change.location + "?time=" + photo.modifiedOn}
                      className={classes.dishPhotoImage}
                    />
                  </div>
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.productInformation}
                  data-enzyme="product-title"
                >
                  {photo.photoType === "hero" ||
                  photo.photoType === "listing" ? (
                    <Typography className={classes.productTitle}>
                      {capitalizeFirstLetter(photo.photoType)} Photo
                    </Typography>
                  ) : (
                    <>
                      <Typography className={classes.productTitle}>
                        {
                          photo.product.title[
                            getDefaultLanguage(photo.product.title)
                          ]
                        }
                      </Typography>
                      <Typography className={classes.productDescription}>
                        {
                          photo.product.description[
                            getDefaultLanguage(photo.product.description)
                          ]
                        }
                      </Typography>
                    </>
                  )}
                </TableCell>
                <TableCell align="left" className={classes.updatedBy}>
                  {getUploadedBy(classes, photo.vendorName, photo.agentEmail)}
                </TableCell>
                <TableCell align="right" className={classes.dateSubmitted}>
                  {moment(photo.modifiedOn).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default TableContent;
