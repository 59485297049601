import { white } from "../common/colors";
import red from "@material-ui/core/colors/red";

export default {
  action: {
    hover: "#edf0f7",
  },
  common: {
    white: "rgba(255, 255, 255, 0.87)",
    black: "#2d3645",
  },
  primary: {
    light: "rgb(102, 176, 209)",
    main: "rgb(78, 174, 217)",
    dark: "rgb(43, 154, 204)",
    contrastText: "#fff",
  },
  secondary: {
    light: "rgba(0,0,0,0.8)",
    main: "rgba(0,0,0,0.9)",
    dark: "rgba(0,0,0,1)",
    contrastText: "#fff",
  },
  success: {
    contrastText: white,
    main: "#45B880",
    light: "#F1FAF5",
    dark: "#00783E",
  },
  info: {
    contrastText: white,
    main: "#1070CA",
    light: "#F1FBFC",
    dark: "#007489",
  },
  warning: {
    contrastText: white,
    main: "#FFB822",
    light: "#FDF8F3",
    dark: "#95591E",
  },
  danger: {
    contrastText: white,
    main: "#ED4740",
    light: "#FEF6F6",
    dark: "#BF0E08",
  },
  text: {
    primary: "#12161B",
    secondary: "#66788A",
    disabled: "#A6B1BB",
  },
  background: {
    default: "#f8fafc",
    dark: "#172B4D",
    paper: white,
  },
  border: "#DFE3E8",
  divider: "#DFE3E8",
  status: {
    pending: "#d9a732",
    rejected: "#d61f26",
    success: "green",
  },
  changeValues: {
    new: "rgba(151, 230, 48, 0.24)",
    old: "rgba(255, 140, 144, 0.24)",
  },
  custom: {
    primary: "rgb(232, 107, 106)",
    secondary: "rgb(61, 173, 132)",
  },
};
