import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ModalAppBar from "./ModalAppbar";
import styles from "./styles";
import SectionBox from "../SectionBox";
import DatePicker from "../DatePicker";
import ClosedAllDayPicker from "./ClosedAllDayPicker";
import CustomizedSelects from "../EditSchedule/Select";
import { isClosedWholeDay } from "../../utility/specialday";

const SpecialHourModal = ({
  classes,
  closeEditScheduleModal,
  open,
  t,
  onSubmit,
  data,
  isSaving,
}) => {
  const [form, setForm] = useState({
    start_time: "00:00:00",
    end_time: "00:00:00",
    isClosedAllDay: "yes",
    type: "closed",
    date: "",
    end_date: "",
  });

  useEffect(() => {
    const { change } = data;
    if (!change) {
      return;
    }
    const isClosedAllDay = isClosedWholeDay(change.start_time, change.end_time)
      ? "yes"
      : "no";
    setForm({
      date: change.date,
      end_date: change.end_date,
      isClosedAllDay,
      start_time: change.start_time,
      end_time: change.end_time,
      type: change.type,
    });
  }, [data]);

  const handleFromTimeChange = e => {
    const { target } = e;
    setForm({ ...form, start_time: target.value });
  };

  const handleToTimeChange = e => {
    const { target } = e;
    setForm({ ...form, end_time: target.value });
  };

  const handleFromDateChange = e => {
    const { target } = e;
    setForm({ ...form, date: target.value, end_date: target.value });
  };

  const handleSetCloseAllDay = e => {
    const { target } = e;
    setForm({ ...form, isClosedAllDay: target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({
      date: form.date,
      end_date: form.date,
      start_time: form.start_time,
      end_time: form.end_time,
      type: form.isClosedAllDay === "yes" ? "closed" : "delivery",
    });
  };

  return (
    <Modal open={open} onClose={closeEditScheduleModal} disableAutoFocus>
      <form className={classes.bodyContainer} onSubmit={handleSubmit}>
        <ModalAppBar
          onClose={closeEditScheduleModal}
          isFormDirty
          isSaving={isSaving}
          formType="save"
          t={t}
        />
        <div style={{ padding: 15 }}>
          <SectionBox title="Holidays and Exceptions">
            <div className={classes.datePickerBox}>
              <div className={classes.datePickerWrapper}>
                <div style={{ marginRight: 20 }}>
                  <DatePicker
                    label="Date"
                    onChange={handleFromDateChange}
                    value={form.date}
                  />
                </div>
                {/* <DatePicker
                  label="to"
                  onChange={handleToDateChange}
                  value={form.end_date}
                /> */}
              </div>
              <ClosedAllDayPicker
                handleSetCloseAllDay={handleSetCloseAllDay}
                isClosedAllDay={form.isClosedAllDay}
                t={t}
              />
            </div>

            {form.isClosedAllDay === "no" && (
              <>
                <div
                  style={{
                    marginBottom: 15,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccessTimeIcon style={{ marginRight: 8 }} />
                  What hours will you be open?
                </div>
                <div
                  style={{ display: "flex", paddingLeft: 32, paddingRight: 32 }}
                >
                  <div style={{ marginRight: 40 }}>
                    <CustomizedSelects
                      value={form.start_time}
                      onChange={handleFromTimeChange}
                    />
                  </div>
                  <CustomizedSelects
                    value={form.end_time}
                    onChange={handleToTimeChange}
                  />
                </div>
              </>
            )}
          </SectionBox>
        </div>
      </form>
    </Modal>
  );
};

SpecialHourModal.propTypes = {
  classes: PropTypes.object.isRequired,
  closeEditScheduleModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SpecialHourModal);
