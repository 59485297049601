import { AccessTime } from "@material-ui/icons";
import ManageTime from "./containers/ManageTime";

const TimeManagement = () => {
  return {
    id: "time-management",
    pages: [
      {
        path: "/opening-times",
        showOnSideBar: true,
        component: ManageTime,
        label: "Opening Times",
        isProtected: true,
        icon: AccessTime,
      },
    ],
  };
};

export default TimeManagement;
