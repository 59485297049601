import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import Divider from "@material-ui/core/Divider";
import DaySelect from "./DaySelect";
import CustomizedSelects from "./Select";
import ModalAppBar from "./ModalAppBar";
import styles from "./styles";
import ProductSection from "./ProductSection";

const ProductDetailModal = ({
  classes,
  data,
  closeEditScheduleModal,
  open,
  onSubmit,
  isSaving,
}) => {
  const defaultGroupedSchedules = {};
  const [groupedSchedules, setGroupedSchedules] = useState(
    defaultGroupedSchedules,
  );

  useEffect(() => {
    const groupedSchedulesInitial = _.chain(data.changes)
      .groupBy(item => {
        return `${item.start}--${item.end}`;
      })
      .value();

    setGroupedSchedules(groupedSchedulesInitial);
  }, [data]);

  const handleUpdateScheduleHour = (event, key, type) => {
    const { target } = event;
    const newGroupedSchedules = { ...groupedSchedules };
    const schedules = newGroupedSchedules[key].map(schedule => ({
      ...schedule,
      [type]: target.value,
    }));

    delete newGroupedSchedules[key];

    const newKey = `${schedules[0].start}--${schedules[0].end}`;
    newGroupedSchedules[newKey] = schedules;
    setGroupedSchedules(newGroupedSchedules);
  };

  const handleChangeMultiple = (event, key) => {
    const { value } = event.target;

    const filtered = value.map(v => {
      return {
        weekday: v,
        start: key.slice(0).split("--")[0],
        end: key.slice(0).split("--")[1],
        type: "delivery",
      };
    });

    if (filtered.length === 0) {
      const newGroupSchedules = { ...groupedSchedules };
      delete newGroupSchedules[key];
      setGroupedSchedules(newGroupSchedules);
      return;
    }

    setGroupedSchedules(previous => {
      return {
        ...previous,
        [key]: filtered,
      };
    });
  };

  const handleSubmit = () => {
    const schedules = [];
    Object.keys(groupedSchedules).forEach(key => {
      schedules.push(...groupedSchedules[key]);
    });

    onSubmit(schedules);
  };

  return (
    <Modal open={open} onClose={closeEditScheduleModal} disableAutoFocus>
      <div className={classes.bodyContainer}>
        <ModalAppBar
          onClose={closeEditScheduleModal}
          handleSave={handleSubmit}
          isFormDirty
          isSaving={isSaving}
          formType="update"
        />
        <div style={{ padding: 15 }}>
          <ProductSection title="Delivery Hours" actionText="ADD HOURS">
            <div style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: 16 }}>
              During these times, customers can place orders and riders will be
              available for delivery.
            </div>
            <br />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ flex: 1, display: "flex" }}>
                <div style={{ flex: 2 }}>Day(s)</div>
                <div style={{ flex: 1 }}>From</div>
                <div style={{ flex: 1 }}>To</div>
              </div>
              <Divider style={{ marginTop: 10, marginBottom: 15 }} />
            </div>
            {Object.entries(groupedSchedules).map(([key, values]) => (
              <div
                key={key}
                style={{
                  flex: 1,
                  display: "flex",
                  marginBottom: 10,
                  padding: 10,
                }}
              >
                <div style={{ flex: 2, marginRight: 5 }} key={key}>
                  <DaySelect
                    value={values.map(value => value.weekday)}
                    onChange={event => handleChangeMultiple(event, key)}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  {" "}
                  <div style={{ flex: 1 }}>
                    {" "}
                    <CustomizedSelects
                      value={
                        values[0].start === "23:59:59"
                          ? "00:00:00"
                          : values[0].start
                      }
                      onChange={event => {
                        handleUpdateScheduleHour(event, key, "start");
                      }}
                    />
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  {" "}
                  <CustomizedSelects
                    value={
                      values[0].end === "23:59:59" ? "00:00:00" : values[0].end
                    }
                    onChange={event => {
                      handleUpdateScheduleHour(event, key, "end");
                    }}
                    disabledFrom={values[0].start}
                  />
                </div>
              </div>
            ))}
          </ProductSection>
        </div>
      </div>
    </Modal>
  );
};

ProductDetailModal.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  closeEditScheduleModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ProductDetailModal);
