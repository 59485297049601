import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { tablestyles, changesStyles } from "./styles";
import { TYPE_OF_CHANGE_CHOICE_GROUP } from "../../../App/common/constant";
import { getDefaultLanguage } from "../../../utility/information";
import ChoiceGroupDetailModal from "./ChoiceGroupDetailModal";

const {
  CHOICE_GROUP_CREATE,
  CHOICE_GROUP_QUANTITY_CHANGE,
  CHOICE_GROUP_TITLE_CHANGE,
  CHOICE_ADD,
} = TYPE_OF_CHANGE_CHOICE_GROUP;

const useStyles = makeStyles(tablestyles);
const useChangesStyles = makeStyles(changesStyles);

const ChoiceGroup = ({
  handleChoiceCheck,
  choiceProduct,
  // TODO Temp fix only, need to refactor!
  handleChoiceProductUpdate,
  setChoiceGroupNotification,
  isChecked,
}) => {
  const classes = useStyles();
  const changesClasses = useChangesStyles();

  const [choiceGroupChanges, setChoiceGroupChanges] = useState({
    title: {},
    quantity: {
      minimum: [],
      maximum: [],
    },
    choices: [],
    isTitleChanged: false,
    choice_group: {},
    choiceProduct: {},
  });
  const [choiceGroupModalOpen, setChoiceGroupModalOpen] = useState(false);

  const handleChoiceRowClick = id => {
    setChoiceGroupModalOpen(true);
  };
  const handleChoiceGroupModalClose = () => {
    setChoiceGroupModalOpen(false);
  };
  /**
   * Utility for grouping
   * @param {*} data
   * @param {*} key
   */
  const _getGrouppedOnKey = (data, key) => {
    return _.groupBy(data, key) || {};
  };
  /**
   * Helper function to prepare choice group data as per UI
   */
  const _getChoiceGroupCreateChanges = useCallback(() => {
    let change = {
      title: {},
      quantity: {
        minimum: [],
        maximum: [],
      },
      typeOfChange: "",
      choices: [],
      isTitleChanged: false,
      choice_group_id: choiceProduct.choice_group_id,
      choice_group: {},
      choiceProduct: choiceProduct,
    };
    let choiceGroupId = choiceProduct.choice_group_id;
    let changes = choiceProduct.changes;
    if (choiceGroupId.startsWith("NEWCG")) {
      change.title = changes[0].title;
      let maxQuantity = {
        new_value:
          changes[0].quantity && changes[0].quantity.maximum
            ? changes[0].quantity.maximum
            : "",
      };
      let minQuantity = {
        new_value:
          changes[0].quantity && changes[0].quantity.minimum
            ? changes[0].quantity.minimum
            : "",
      };
      change.quantity.maximum.push(maxQuantity);
      change.quantity.minimum.push(minQuantity);
      change.typeOfChange = changes[0].type_of_change;
    } else {
      let changesByType = _getGrouppedOnKey(changes, "type_of_change");
      // console.log("Nimbus: _getChoiceGroupCreateChanges -> changesByType", changesByType)
      let orignalChiceGroupDetail = choiceProduct.choice_group;
      changes.forEach(element => {
        if (element.type_of_change === CHOICE_GROUP_QUANTITY_CHANGE) {
          let quantityChangeList = changesByType[CHOICE_GROUP_QUANTITY_CHANGE];
          let quantityChangeListGroupped = _getGrouppedOnKey(
            quantityChangeList,
            "minMax",
          );
          // console.log("Nimbus: _getChoiceGroupCreateChanges -> quantityChangeListGroupped", quantityChangeListGroupped)
          change.quantity.minimum = quantityChangeListGroupped["minimum"] || [];
          change.quantity.maximum = quantityChangeListGroupped["maximum"] || [];
        } else if (element.type_of_change === CHOICE_ADD) {
          change.choices = changesByType[CHOICE_ADD];
          change.price = element.price;
        } else if (element.type_of_change === CHOICE_GROUP_TITLE_CHANGE) {
          const titleChange = changesByType[CHOICE_GROUP_TITLE_CHANGE];
          change.isTitleChanged = true;
          change.title = titleChange;
        }
      });
      //this change will not have title detail, will take out title from original choice detial
      change.title =
        !change.isTitleChanged && Object.keys(change.title).length === 0
          ? orignalChiceGroupDetail.title
          : change.title;
      change.choice_group = orignalChiceGroupDetail;
    }
    return change;
  }, [choiceProduct]);

  /**
   * Wraper function to set chloice group in component state
   */
  useEffect(() => {
    let choiceGroupChanges = _getChoiceGroupCreateChanges(choiceProduct);
    setChoiceGroupChanges(choiceGroupChanges);
  }, [_getChoiceGroupCreateChanges, choiceProduct]);

  return (
    <>
      {" "}
      <Table className={classes.table} key={choiceGroupChanges.choice_group_id}>
        <TableHead>
          {choiceGroupChanges.typeOfChange === CHOICE_GROUP_CREATE &&
            choiceGroupChanges.choiceProduct.status === "pending" && (
              <TableRow
                onClick={e =>
                  handleChoiceRowClick(e, choiceProduct.choice_group_id)
                }
              >
                <TableCell align="left">
                  <Checkbox
                    onClick={e =>
                      handleChoiceCheck(e, choiceProduct.choice_group_id)
                    }
                    checked={isChecked(choiceProduct.choice_group_id)}
                  />

                  <Box
                    component="span"
                    p={1}
                    className={classes.choicePriceChange}
                  >
                    <Typography
                      variant="body2"
                      className={`${classes.textBold} ${changesClasses.changeValuesNew}`}
                    >
                      {
                        choiceGroupChanges.title[
                          getDefaultLanguage(choiceGroupChanges.title)
                        ]
                      }
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  {choiceGroupChanges.quantity.minimum.map((minQuantity, i) => (
                    <React.Fragment key={i}>
                      MIN:
                      <Box
                        component="span"
                        p={1}
                        className={classes.choicePriceChange}
                      >
                        <Typography
                          variant="body2"
                          className={changesClasses.changeValuesNew}
                        >
                          {minQuantity.new_value || "-"}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  ))}{" "}
                  {choiceGroupChanges.quantity.maximum.map((maxQuantity, i) => (
                    <React.Fragment key={i}>
                      MAX:
                      <Box
                        component="span"
                        p={1}
                        className={classes.choicePriceChange}
                      >
                        <Typography
                          variant="body2"
                          className={changesClasses.changeValuesNew}
                        >
                          {maxQuantity.new_value || "-"}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  ))}
                </TableCell>
              </TableRow>
            )}
          {choiceGroupChanges.typeOfChange !== CHOICE_GROUP_CREATE &&
            choiceGroupChanges.choiceProduct.status === "pending" && (
              <TableRow
                onClick={e =>
                  handleChoiceRowClick(e, choiceProduct.choice_group_id)
                }
              >
                <TableCell align="left">
                  <Checkbox
                    onClick={e =>
                      handleChoiceCheck(e, choiceProduct.choice_group_id)
                    }
                    checked={isChecked(choiceProduct.choice_group_id)}
                  />
                  {choiceGroupChanges.isTitleChanged ? (
                    <>
                      {choiceGroupChanges.title.map((title, i) => (
                        <Box
                          component="span"
                          p={1}
                          className={classes.choiceBlock}
                          key={i}
                        >
                          <Typography
                            className={`${classes.lineThrough} ${changesClasses.changeValuesOld}`}
                          >
                            {title.old_value || "-"}
                          </Typography>
                          <Typography
                            variant="body2"
                            className={changesClasses.changeValuesNew}
                          >
                            {title.new_value || "-"}
                          </Typography>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Box
                      component="span"
                      p={1}
                      className={classes.choicePriceChange}
                    >
                      <Typography variant="body2" className={classes.textBold}>
                        {
                          choiceGroupChanges.title[
                            getDefaultLanguage(choiceGroupChanges.title)
                          ]
                        }
                      </Typography>
                    </Box>
                  )}
                </TableCell>
                <TableCell align="right">
                  {choiceGroupChanges.quantity.minimum.map((minQuantity, i) => (
                    <React.Fragment key={i}>
                      MIN:{" "}
                      <Box
                        component="span"
                        p={1}
                        className={classes.choicePriceChange}
                      >
                        <Typography
                          className={`${classes.lineThrough} ${changesClasses.changeValuesOld}`}
                        >
                          {minQuantity.old_value ?? "-"}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={changesClasses.changeValuesNew}
                        >
                          {minQuantity.new_value ?? "-"}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  ))}{" "}
                  {choiceGroupChanges.quantity.maximum.map((maxQuantity, i) => (
                    <React.Fragment key={i}>
                      MAX:
                      <Box
                        component="span"
                        p={1}
                        className={classes.choicePriceChange}
                      >
                        <Typography
                          className={`${classes.lineThrough} ${changesClasses.changeValuesOld}`}
                        >
                          {maxQuantity.old_value ?? "-"}
                        </Typography>
                        <Typography
                          variant="body2"
                          className={changesClasses.changeValuesNew}
                        >
                          {maxQuantity.new_value ?? "-"}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  ))}
                </TableCell>
              </TableRow>
            )}
        </TableHead>
        <TableBody>
          {choiceGroupChanges.choices.map((choice, i) => (
            <React.Fragment key={i}>
              {choice.type_of_change == CHOICE_ADD && (
                <TableRow key={choice.id}>
                  <TableCell align="left" className={classes.choiceAlignment}>
                    <Typography variant="body2">
                      {choice.title[getDefaultLanguage(choice.title)] || "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Box
                      component="span"
                      p={1}
                      className={classes.choicePriceChange}
                    >
                      <Typography variant="body2">
                        {choice.price || "-"}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <ChoiceGroupDetailModal
        choiceGroupModalOpen={choiceGroupModalOpen}
        handleChoiceGroupModalClose={handleChoiceGroupModalClose}
        choiceGroupChanges={choiceGroupChanges}
        // TODO temp fix, need to refactor!!
        handleChoiceProductUpdate={handleChoiceProductUpdate}
        setChoiceGroupNotification={setChoiceGroupNotification}
      />
    </>
  );
};

export default ChoiceGroup;
