import * as React from "react";
import styles from "./styles";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles(styles);

const CancelButton = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Button classes={classes} {...rest}>
      <Box ml={1}>{children}</Box>
    </Button>
  );
};

export default CancelButton;
