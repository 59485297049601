import * as React from "react";

const appcontext = {
  choiceGroups: [],
};

const context = React.createContext(appcontext);

export const { Provider, Consumer } = context;
export default context;
