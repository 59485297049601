import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { productSectionStyles } from "./styles";

const useStyles = makeStyles(productSectionStyles);

const ProductSection = ({ children, title, desc }) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.sectionHeader}>
        {title}{" "}
        <span className={classes.sectionHeaderSpan}>{desc && `- ${desc}`}</span>
      </div>
      <div className={classes.sectionContent}>{children}</div>
    </div>
  );
};

ProductSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export default ProductSection;
