import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import Button from "@material-ui/core/Button";
import styles from "./styles";

const useStyles = makeStyles(styles);

const ModalAppBar = ({ onClose, handleSave, isFormDirty, isSaving }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: "rgb(45, 54, 69)" }}>
        <Toolbar>
          <IconButton
            onClick={onClose}
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
          >
            <KeyboardBackspace />
          </IconButton>

          <Typography variant="h4" color="inherit" className={classes.flex}>
            Amend Changes
          </Typography>

          <Button
            color="primary"
            onClick={handleSave}
            disabled={!isFormDirty || isSaving}
            variant="contained"
            data-cp="product-save-btn"
          >
            {isSaving ? "Saving" : "Save"}
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

ModalAppBar.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
};

export default ModalAppBar;
