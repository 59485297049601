export const COUNTRY_LIST = {
  BD: { code: "BD", name: "Bangladesh", index: 0 },
  HK: { code: "HK", name: "Hong Kong", index: 1 },
  KH: { code: "KH", name: "Cambodia", index: 2 },
  LA: { code: "LA", name: "Laos", index: 3 },
  MM: { code: "MM", name: "Myanmar", index: 4 },
  MY: { code: "MY", name: "Malaysia", index: 5 },
  PK: { code: "PK", name: "Pakistan", index: 6 },
  PH: { code: "PH", name: "Philippines", index: 7 },
  SG: { code: "SG", name: "Singapore", index: 8 },
  TW: { code: "TW", name: "Taiwan", index: 9 },
  TH: { code: "TH", name: "Thailand", index: 10 },
  BG: { code: "BG", name: "Bulgaria", index: 11 },
  RO: { code: "RO", name: "Romania", index: 12 },
  CA: { code: "CA", name: "Canada", index: 13 },
  FI: { code: "FI", name: "Finland", index: 14 },
  NO: { code: "NO", name: "Norway", index: 15 },
  SE: { code: "SE", name: "Sweden", index: 16 },
};
