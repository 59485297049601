import React, { useContext } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import ProductSection from "./ProductSection";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { getDefaultLanguage } from "../../../utility/information";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { VariantChoiceGroupAssociationSection as VariantChoiceGroup } from "@deliveryhero/fp-apollo-components";
import t from "../../../utility/Translate";
import countryToCodeAndSymbol, {
  codeToSymbol,
} from "../../utility/translation";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import Context from "../../context/choiceGroups";

const styles = theme => ({
  categoryWrapper: {
    marginTop: 20,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  variantNameField: {
    flex: "0 1 45.6%",
    marginLeft: "7%",

    [theme.breakpoints.down("xs")]: {
      flex: "0 0 100%",
      marginLeft: "0%",
    },
  },
  priceField: {
    [theme.breakpoints.down("xs")]: {
      flex: "0 0 100%",
    },
  },
});

export const VariantsWrapper = styled(Grid)`
  display: flex;

  ${props => props.theme.breakpoints.down("xs")} {
    flex-flow: row wrap;
  }
`;

const NormalTextField = styled(TextField)`
  width: 100%;
`;

export const TitleTextField = styled(NormalTextField)``;

export const DescTextField = styled(NormalTextField)``;

const useStyles = makeStyles(styles);

const ProductDetail = ({
  hasTitleChange,
  hasDescriptionChange,
  title,
  description,
  handleChange,
  handleCategoryChange,
  handlePriceChange,
  handleVariantionNameChange,
  category,
  variants,
  currency,
  currentCountry,
  variantChoiceGroupAssociation,
  categories,
  hasCategoryChange,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const variantsWithoutNewVariants = variants.filter(v => !v.type_of_change);
  const { choiceGroups } = useContext(Context);
  const isCreatingProduct = !isEmpty(variants) && !variants[0].id;
  const hasPriceList = !!(variants && variants.length);
  const languageKeys = countryToCodeAndSymbol[currentCountry.code].map(
    country => country.code,
  );

  return (
    <>
      <ProductSection title={"Item Details"}>
        <div>
          {languageKeys.map(language => (
            <TitleTextField
              key={language}
              name="title"
              label={`Item Name [${codeToSymbol[language]}]`}
              defaultValue={title[language]}
              margin="normal"
              onChange={e => handleChange(e, language)}
              inputProps={{
                "data-hj-whitelist": true,
                "data-enzyme": `title_${language}`,
                "data-cp": `title_${language}`,
              }}
              disabled={!hasTitleChange}
              spellCheck={true}
            />
          ))}
        </div>
        <div>
          {languageKeys.map(language => (
            <DescTextField
              key={language}
              name="description"
              label={`Item Description [${codeToSymbol[language]}]`}
              defaultValue={description[language]}
              margin="normal"
              fullWidth
              onChange={e => handleChange(e, language)}
              multiline={true}
              spellCheck={true}
              inputProps={{
                "data-hj-whitelist": true,
                "data-enzyme": `description_${language}`,
                "data-cp": `description_${language}`,
              }}
              disabled={!hasDescriptionChange}
            />
          ))}
        </div>
        {categories && (
          <div className={classes.categoryWrapper}>
            <FormControl
              className={classes.categoryWrapper}
              disabled={hasCategoryChange && categories}
            >
              <InputLabel>Item Category</InputLabel>
              <Select
                value={category}
                onChange={handleCategoryChange}
                input={
                  <Input
                    name="category"
                    data-cp="category"
                    data-enzyme="category"
                  />
                }
                data-cp="product-detail-modal-category"
              >
                {categories.map(category => (
                  <MenuItem value={category.id} key={category.id}>
                    {category.title[getDefaultLanguage(category.title)]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
      </ProductSection>

      <br />
      <br />

      {hasPriceList && (
        <ProductSection
          title={"Prices and Sizes"}
          desc={"does this item come in multiple sizes?  "}
        >
          {variants.map(
            (
              { unitPrice = 0, id, isTitleChanged, isPriceChanged, title },
              index,
            ) => (
              <VariantsWrapper
                theme={theme}
                key={id || `variant_change_${index}`}
              >
                <TextField
                  key={id}
                  name="unitPrice"
                  label="Price"
                  defaultValue={unitPrice.toFixed(2)}
                  margin="normal"
                  onChange={e => handlePriceChange(e, id || index)}
                  className={classes.priceField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currency}
                      </InputAdornment>
                    ),
                  }}
                  // eslint-disable-next-line
                  inputProps={{
                    "data-cp": "productdetail_price",
                    "data-enzyme": "productdetail_price",
                  }}
                  disabled={!isPriceChanged}
                />
                {!isEmpty(title) &&
                  languageKeys.map(language => (
                    <TextField
                      key={`variant_name_${language}`}
                      inputProps={{
                        "data-cp": `variant_name_${language}`,
                        "data-enzyme": `variant_name_${language}`,
                      }}
                      name="title"
                      label={`Size name [${codeToSymbol[language]}]`}
                      defaultValue={title[language]}
                      margin="normal"
                      className={classes.variantNameField}
                      disabled={!isTitleChanged}
                      onChange={e =>
                        handleVariantionNameChange(e, id || index, language)
                      }
                    />
                  ))}
              </VariantsWrapper>
            ),
          )}
        </ProductSection>
      )}

      <br />
      <br />

      {/* // we dont support variant choice group when creating product at this moment */}
      {!isCreatingProduct && (
        <VariantChoiceGroup
          className={classes.choiceGroupSection}
          t={t}
          variants={variantsWithoutNewVariants}
          choiceGroups={choiceGroups}
          // May need to add handler to let agent ammend in the future
          // handleChoiceGroupSelect={useCallback(() => {}, [])}
          variantChoiceGroupAssociation={variantChoiceGroupAssociation}
          currentLanguage={languageKeys[0]}
          selectingDisabled={true}
        />
      )}
    </>
  );
};

ProductDetail.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  category: PropTypes.string,
  hasTitleChange: PropTypes.bool.isRequired,
  hasDescriptionChange: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
  currentCountry: PropTypes.object.isRequired,
  categories: PropTypes.array,
};

export default ProductDetail;
