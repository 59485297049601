import Home from "./containers/Home";
import PhotoList from "./containers/PhotoList";
import { DashboardOutlined as DashboardIcon } from "@material-ui/icons";

const MenuManagement = app => {
  console.log(app, "app");
  return {
    id: "menu-management",
    pages: [
      {
        path: "/",
        showOnSideBar: true,
        component: Home,
        label: "Menu",
        isProtected: true,
        icon: DashboardIcon,
        exact: true,
      },
      {
        path: "/photo/menu/:vendorId/:countryCode",
        showOnSideBar: false,
        label: "Opening Time",
        isProtected: true,
        component: PhotoList,
        exact: true,
      },
    ],
  };
};

export default MenuManagement;
