import React, { useState } from "react";
import Login from "./containers/Login";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import { ProtectedRoute, PublicRoute } from "./components/RouteFactory";
import CssBaseline from "@material-ui/core/CssBaseline";
import MenuManagement from "../MenuManagement";
import TimeManagement from "../TimeManagement";
import PhotoManagement from "../PhotoManagement";
import { BrowserRouter as ReactRouter, Route, Switch } from "react-router-dom";
import DefaultLayout from "./layouts/NextLayout";
import EmptyLayout from "./layouts/EmptyLayout";
import { getCurrentCountry } from "./utility";
import Cookies from "js-cookie";

export const AppBarContext = React.createContext({});

const PLUGINS = [MenuManagement, TimeManagement, PhotoManagement];

const app = {
  version: "1",
};

function App() {
  const [customContext, setCustomContext] = useState({
    currentCountry: getCurrentCountry(Cookies.get("country")),
  });

  const renderRoute = (page, setCustomContext, customContext) => {
    return page.isProtected ? (
      <ProtectedRoute
        component={page.component}
        path={page.path}
        key={page.path}
        exact={page.exact}
        setCustomContext={setCustomContext}
        customContext={customContext}
      />
    ) : (
      <PublicRoute
        component={page.component}
        path={page.path}
        key={page.path}
        exact={page.exact}
        setCustomContext={setCustomContext}
        customContext={customContext}
      />
    );
  };

  let sideBar = [];
  let routes = [];

  PLUGINS.forEach(plugin => {
    const pages = plugin(app).pages.filter(page => page.showOnSideBar);
    sideBar.push(...pages);
    routes.push(...plugin(app).pages.map(page => page.path));
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ReactRouter>
        <AppBarContext.Provider value={customContext}>
          <Switch>
            <Route exact path={routes}>
              <DefaultLayout
                sideBar={sideBar}
                setCustomContext={setCustomContext}
              >
                {PLUGINS.map(plugin => (
                  <React.Fragment key={plugin(app).id}>
                    {plugin(app).pages.map(page =>
                      renderRoute(page, setCustomContext, customContext),
                    )}
                  </React.Fragment>
                ))}
              </DefaultLayout>
            </Route>
            <Route path={"/login"}>
              <EmptyLayout>
                <Route component={Login} path={"/login"} />
              </EmptyLayout>
            </Route>
          </Switch>
        </AppBarContext.Provider>
      </ReactRouter>
    </ThemeProvider>
  );
}

export default App;
