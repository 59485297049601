export default function reducer(state, action) {
  switch (action.type) {
    case "REQUEST_START":
      return { ...state, loading: true };
    case "REQUEST_END":
      return { ...state, loading: false };
    case "FETCH_CHOICE_GROUPS_REQUESTS":
      return { ...state, data: action.data };
    // Below is a placeholder
    // case "FETCH_MENU_IMAGES":
    //     return { ...state, data: action.data };
    // case "POST_PRODUCT":
    //     return { ...state, data: action.data };
    // case "PATCH_PRODUCT":
    //     return { ...state, data: action.data };
    // case "POST_CHOICE_GROUP":
    //     return { ...state, data: action.data };
    // case "PATCH_CHOICE_GROUP":
    //     return { ...state, data: action.data };
    default:
      throw new Error();
  }
}
