import React from "react";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { withStyles, createStyles } from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";

const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    bootstrapFormLabel: {
      fontSize: 18,
    },
  });

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 3,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "8px 24px 8px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 3,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const CustomizedSelects = ({ classes, value, onChange, disabledFrom }) => {
  const isDisabled = time => {
    if (!disabledFrom) {
      return false;
    }

    // We will always let user pick 12:00 am
    // no matter what
    if (time === "00:00:00" || time === "23:59:59") {
      return false;
    }
    const beginningTime = moment(disabledFrom, "HH:mm:ss");
    const endTime = moment(time, "HH:mm:ss");

    return beginningTime.isSameOrAfter(endTime);
  };

  return (
    <div className={classes.root} autoComplete="off">
      <FormControl>
        <NativeSelect
          value={value}
          onChange={onChange}
          input={
            <BootstrapInput name="age" id="age-customized-native-simple" />
          }
          placeholder="Select Time"
        >
          {_.range(96).map(d => (
            <option
              value={moment.utc(d * 900 * 1000).format("HH:mm:ss")}
              key={d}
              disabled={isDisabled(
                moment.utc(d * 900 * 1000).format("HH:mm:ss"),
              )}
            >
              {moment.utc(d * 900 * 1000).format("hh:mm A")}
            </option>
          ))}
          <option value="00:00:00" disabled={false}>
            12:00 AM
          </option>
        </NativeSelect>
      </FormControl>
    </div>
  );
};

CustomizedSelects.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabledFrom: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedSelects);
