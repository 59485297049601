import React, { useCallback, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RejectButton from "../RejectButton";
import Box from "@material-ui/core/Box";
import useStyles, { useStylesControlLabel } from "./styles";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CancelButton from "../CancelButton";

const rejectedReasons = [
  "Photo is too blurry.  Please try again and submit a new photo.",
  "Photo is not in landscape format.  Please try again and submit a new photo.",
  "Watermarks or logos should not be present. Please try again and submit a new photo.",
  "Photo is too close up. Please take a photo further away and try again.",
  "Misleading side product in the photo.  Please try again and submit a new photo.",
  "Dish photo does not match with item name. Please try again and submit a new photo.",
  "Item is not in the center of the photo. Please ensure item is in the middle of the photo for best browsing experience.",
];

const RejectModal = ({
  loading,
  header,
  subheader,
  isRejectModalOpen,
  setIsRejectModalOpen,
  onSubmit,
}) => {
  const classes = useStyles();
  const classesForControlLabel = useStylesControlLabel();
  const [state, setState] = React.useState({});
  const [otherReason, setOtherReason] = React.useState("");
  const [formErrors, setFormErrors] = React.useState([]);

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  useEffect(() => {
    setFormErrors([]);
  }, [state]);

  const handleClose = useCallback(() => {
    setIsRejectModalOpen(false);
  }, [setIsRejectModalOpen]);

  const handleSubmit = e => {
    e.preventDefault();
    const rejectReasons = [];

    for (let [key, value] of Object.entries(state)) {
      if (value === true) {
        if (key === "other") {
          if (otherReason.trim() === "") {
            setFormErrors(["Please type the other reason"]);
            return;
          }
          key = otherReason;
        }
        rejectReasons.push(key);
      }
    }

    if (rejectReasons.length === 0) {
      setFormErrors(["Please select reject reason"]);
      return;
    }
    onSubmit(rejectReasons);
  };

  return (
    <Modal
      open={isRejectModalOpen}
      onClose={handleClose}
      className={classes.container}
      data-cp="reject-modal"
    >
      <Paper className={classes.paper}>
        <Box mb={2}>
          <Typography style={{ color: "black" }} variant="h4">
            {header}
          </Typography>
        </Box>

        <Box color="custom.primary" mb={2} fontSize={16}>
          {subheader}
        </Box>

        <FormGroup>
          {formErrors.length > 0 && (
            <Box mb={1}>
              {formErrors.map(formError => (
                <Alert severity="error" key={formError}>
                  {formError}
                </Alert>
              ))}
            </Box>
          )}
          {rejectedReasons.map(reason => (
            <FormControlLabel
              key={reason}
              control={
                <Checkbox
                  checked={state.checked}
                  onChange={handleChange(reason)}
                  value={reason}
                  data-cp="reject-reason-checkbox"
                />
              }
              label={reason}
            />
          ))}

          <FormControlLabel
            control={
              <Checkbox
                checked={state.checked}
                onChange={handleChange("other")}
                value={"other"}
              />
            }
            label={
              <Box
                flex="1"
                flexDirection="row"
                display="flex"
                alignItems="center"
              >
                <Box component="span" mr={1}>
                  Other:
                </Box>
                <TextField
                  name="otherReason"
                  type="text"
                  onChange={e => setOtherReason(e.target.value)}
                  inputProps={{ style: { fontSize: "14px" } }}
                  fullWidth
                />
              </Box>
            }
            classes={classesForControlLabel}
          />

          <Box mt={2} display="flex" justifyContent="flex-end">
            <Box mr={1}>
              <CancelButton
                variant="outlined"
                onClick={handleClose}
                disabled={loading}
              >
                Cancel
              </CancelButton>
            </Box>
            <RejectButton
              variant="contained"
              onClick={handleSubmit}
              data-cp="reject-btn"
              disabled={loading}
            >
              {loading ? "Rejecting" : "Reject"}
            </RejectButton>
          </Box>
        </FormGroup>
      </Paper>
    </Modal>
  );
};

export default RejectModal;
