export const ERROR_LIST = {
  403: {
    status: 403,
    message: "Authorization failed, you don't have permission for this action",
    detail: "",
  },
  401: {
    status: 401,
    message: "Session expired, please login again.",
    detail: "",
  },
  400: {
    status: 400,
    message:
      "An unexpected error occurred, please try again later. If the error persists, please report to the Vendor QC Portal team.",
    detail: "",
  },
  422: {
    status: 422,
    message:
      "An unexpected error occurred, please try again later. If the error persists, please report to the Vendor QC Portal team.",
    detail: "",
  },
  500: {
    status: 500,
    message:
      "An unexpected error occurred, please try again later. If the error persists, please report to the Vendor QC Portal team.",
    detail: "",
  },
};
