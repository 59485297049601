export const photostyles = theme => ({
  button: {
    margin: 0,
    marginRight: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  image: {
    textAlign: "center",
    overflowX: "auto",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  loader: {
    textAlign: "center",
    width: "auto",
    height: "600px",
    marginTop: "150px",
  },
  countryFilterContainer: {
    textAlign: "right",
    paddingTop: theme.spacing(5),
  },
  innerscroll: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  defaultOptions: {
    display: "inline-block",
  },
  moreOptions: {
    display: "inline-block",
    paddingLeft: 10,
    borderLeft: "1px solid rgb(235, 235, 235)",
    marginLeft: 5,
  },
});

export const tablestyles = theme => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
    "& tbody": {
      paddingRight: "20px solid transparent",
    },
  },
  checkBoxColumn: {
    paddingLeft: 10,
    paddingRight: 10,
    width: 5,
  },
  photoCell: {
    padding: 0,
    width: 323,
    paddingTop: 14,
    paddingBottom: 14,
  },
  dishPhotoImage: {
    width: 323,
  },
  tableRoot: {
    width: "100%",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  button: {
    margin: 0,
    marginRight: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  label: {
    fontWeight: "bold",
    width: "auto",
  },
  labelContainer: {
    flexBasis: "20%",
  },
  vendorName: {
    color: "rgb(232, 107, 106)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
  },
  agentEmail: {
    color: theme.palette.custom.secondary,
    fontFamily: "Roboto",
    fontSize: "15px",
    fontWeight: "bold",
  },
  productInformation: {
    "vertical-align": "top",
    minWidth: 450,
    paddingLeft: 16,
  },
  productTitle: {
    color: "rgb(0, 0, 0)",
    fontSize: "15px",
    fontWeight: "normal",
    letterSpacing: 0.46,
    paddingBottom: 8,
  },
  productDescription: {
    color: "rgb(0, 0, 0)",
    fontSize: "14px",
    fontWeight: "normal",
    letterSpacing: 0.4,
  },
  updatedBy: {
    "vertical-align": "top",
  },

  dateSubmitted: {
    "vertical-align": "top",
    fontSize: "14px",
    fontWeight: "bold",
  },
});

export default photostyles;
