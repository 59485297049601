export const style = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  image: {
    textAlign: "center",
    overflowX: "auto",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  loader: {
    textAlign: "center",
    width: "auto",
    height: "600px",
    marginTop: "150px",
  },
  countryFilterContainer: {
    textAlign: "right",
    paddingTop: theme.spacing(5),
  },
  innerscroll: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    padding: theme.spacing(3),
  },
});

export const tablestyles = theme => ({
  table: {
    minWidth: 650,
  },
  tableRoot: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  vendorCode: {
    width: "15%",
  },
  submitDate: {
    width: "15%",
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  label: {
    fontWeight: "bold",
    width: "auto",
  },
  labelContainer: {
    flexBasis: "20%",
  },
});

export default style;
