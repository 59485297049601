export const styles = theme => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
});

export const modalStyles = theme => ({
  root: {
    float: "right",
    "@media (max-width: 700px)": {
      width: "100%",
      minWidth: "100%"
    }
  }
});

export default styles;
