export const styles = theme => ({
  root: {
    "border-left": "5px",
    "border-left-style": "solid",
    "border-left-color": props => {
      switch (props.type) {
        case "pending":
          return theme.palette.status.pending;
        case "rejected":
          return theme.palette.status.rejected;
        default:
          return theme.palette.status.success;
      }
    }
  }
});

export const choiceGroupHighlightBoxStyle = theme => ({
  wrapper: {
    "margin-bottom": "20px"
  }
})

export default styles;
