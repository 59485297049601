import React from "react";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/Select";
import PropTypes from "prop-types";
import { withStyles, createStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { mapsIntToDate } from "../../utility/specialday";

const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    bootstrapFormLabel: {
      fontSize: 18,
    },
  });

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "9px 26px 9px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const CustomizedSelects = ({ classes, value, onChange }) => {
  return (
    <form className={classes.root} autoComplete="off">
      <FormControl fullWidth>
        <NativeSelect
          multiple
          value={value}
          onChange={onChange}
          input={<BootstrapInput name="age" id="age-customized-select" />}
          placeholder="Select Day"
          renderValue={selected =>
            selected.map(s => mapsIntToDate(s)).join(", ")
          }
        >
          <MenuItem
            value={1}
            selected={false}
            style={{ backgroundColor: "transparent" }}
          >
            <Checkbox checked={value.indexOf(1) > -1} color="primary" />
            <ListItemText primary="Mon" />
          </MenuItem>
          <MenuItem value={2} style={{ backgroundColor: "transparent" }}>
            <Checkbox checked={value.indexOf(2) > -1} color="primary" />
            <ListItemText primary="Tue" />
          </MenuItem>
          <MenuItem value={3} style={{ backgroundColor: "transparent" }}>
            <Checkbox checked={value.indexOf(3) > -1} color="primary" />
            <ListItemText primary="Wed" />
          </MenuItem>
          <MenuItem value={4} style={{ backgroundColor: "transparent" }}>
            <Checkbox checked={value.indexOf(4) > -1} color="primary" />
            <ListItemText primary="Thur" />
          </MenuItem>
          <MenuItem value={5} style={{ backgroundColor: "transparent" }}>
            <Checkbox checked={value.indexOf(5) > -1} color="primary" />
            <ListItemText primary="Fri" />
          </MenuItem>
          <MenuItem value={6} style={{ backgroundColor: "transparent" }}>
            <Checkbox checked={value.indexOf(6) > -1} color="primary" />
            <ListItemText primary="Sat" />
          </MenuItem>
          <MenuItem value={0} style={{ backgroundColor: "transparent" }}>
            <Checkbox checked={value.indexOf(0) > -1} color="primary" />
            <ListItemText primary="Sun" />
          </MenuItem>
        </NativeSelect>
      </FormControl>
    </form>
  );
};

CustomizedSelects.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};

export default withStyles(styles)(CustomizedSelects);
