import * as React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { menuItemStyles } from "./styles";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles(styles);
const useMenuItemStyles = makeStyles(menuItemStyles);

const DropDown = () => {
  return <KeyboardArrowDownIcon color="inherit" />;
};

const CountryFilter = ({ countryList, handleFilterClick, currentCountry }) => {
  const classes = useStyles();
  const menuItemClasses = useMenuItemStyles();
  return (
    <Box className={classes.root}>
      <FormControl className={classes.formControl}>
        <Select
          value={currentCountry}
          onChange={event => {
            handleFilterClick(event.target.value);
          }}
          className={`${classes.menuSelector}`}
          IconComponent={props => {
            return (
              <div style={{ right: "12px", color: "white" }} {...props}>
                <DropDown />
              </div>
            );
          }}
          disableUnderline
          MenuProps={{
            classes: {
              paper: classes.paper,
              list: classes.list,
            },
          }}
          inputProps={{
            classes: {
              input: classes.input,
            },
          }}
        >
          {Object.keys(countryList).map(country => (
            <MenuItem
              value={countryList[country].code}
              key={countryList[country].code}
              classes={menuItemClasses}
            >
              {countryList[country].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

CountryFilter.propTypes = {
  countryList: PropTypes.object,
  handleFilterClick: PropTypes.func,
  setCountry: PropTypes.func,
  currentCountry: PropTypes.string,
};

export default CountryFilter;
