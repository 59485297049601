// Palette
import palette from "../palette";
import grey from "@material-ui/core/colors/grey";
import { light } from "@material-ui/core/styles/createPalette";

export default {
  root: {
    height: 36,
    minWidth: 100,
    borderRadius: 4,
  },
  outlined: {
    backgroundColor: "rgb(255, 255, 255)",
    border: "1px solid rgb(235, 235, 235)",
    "&$disabled": {
      backgroundColor: light.action.disabledBackground,
      border: "1px solid transparent",
    },
  },
  contained: {
    border: 0,
    boxShadow: "none",
    backgroundColor: palette.common.white,
    "&:hover": {
      backgroundColor: palette.common.neutral,
    },
    "&$disabled": {
      backgroundColor: light.action.disabledBackground,
      border: 0,
    },
  },
  containedPrimary: {
    border: 0,
    "&$disabled": {
      backgroundColor: light.action.disabledBackground,
      border: 0,
    },
  },
};
