import React, { useState, useEffect } from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import FileCopy from "@material-ui/icons/FileCopy";
import AttachFile from "@material-ui/icons/AttachFile";
import {
  copyToClipboard,
  getDefaultLanguage,
} from "../../../utility/information";
import Notification from "../../../App/components/Notification";
import Box from "@material-ui/core/Box";
import { tablestyles, changesStyles } from "./styles";
import { TYPE_OF_CHANGE } from "../../../App/common/constant";
import { green } from "@material-ui/core/colors";
import get from "lodash/get";
import ChoiceGroup from "./ChoiceGroup";
import { getCategoryObject } from "../../utility/information";

const {
  VARIANT_CREATE,
  VARIANT_DELETE,
  PRODUCT_CREATE,
  CATEGORY_CHANGE,
  VARIANT_UNITPRICE_CHANGE,
} = TYPE_OF_CHANGE;

const useStyles = makeStyles(tablestyles);
const useChangesStyles = makeStyles(changesStyles);

const TableContent = ({
  groupedVendor,
  choiceGroup,
  vendorDetails,
  handleVendorCheck,
  handleAttachFileClicked,
  isVendorChecked,
  setModalStatus,
  setCurrentProduct,
  handleProductCheck,
  handleChoiceCheck,
  isProductChecked,
  isChoiceChecked,
  categories,
}) => {
  const classes = useStyles();
  const changesClasses = useChangesStyles();
  const [collapse, setCollapse] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [choiceGroupNotification, setChoiceGroupNotification] = useState({
    open: false,
    variant: "success",
    message: "",
  });
  const [sortedVendorDetails, set] = useState([]);

  // TODO: Temp fix for the wrong structure
  const [hasPendingChoiceGroups, setHasPendingChoiceGroups] = useState(
    _.mapValues(choiceGroup, () => true),
  );

  useEffect(() => {
    if (!_.isEmpty(vendorDetails)) {
      const newSortedVendorsDetails = _.filter(
        vendorDetails,
        v =>
          choiceGroup[v.vendor_id] != null ||
          groupedVendor[v.vendor_id] != null,
      ).sort(function(a, b) {
        return a.timestamp - b.timestamp;
      });

      set(newSortedVendorsDetails);
      setCollapse(
        newSortedVendorsDetails[0]
          ? [newSortedVendorsDetails[0].vendor_id]
          : [],
      );
    }
  }, [vendorDetails, choiceGroup, groupedVendor]);

  const handleClickRow = id => {
    if (collapse.find(list => list === id)) {
      const filtered = collapse.filter(list => {
        return list !== id;
      });
      setCollapse(filtered);
    } else {
      setCollapse([...collapse, id]);
    }
  };

  const getIconStyle = vendor => {
    const hasMenuImages = vendor.hasMenuImages;
    return {
      opacity: hasMenuImages ? 1 : 0.3,
      pointerEvents: hasMenuImages ? "auto" : "none",
    };
  };

  const isExpanded = id => {
    return collapse.findIndex(item => item === id) >= 0;
  };

  const getChanges = (change, categories, currency, product) => {
    switch (change.type_of_change) {
      case VARIANT_UNITPRICE_CHANGE: {
        return [
          change.old_value && `${currency} ${change.old_value.toFixed(2)}`,
          change.new_value && `${currency} ${change.new_value.toFixed(2)}`,
        ];
      }
      case CATEGORY_CHANGE: {
        let oldValue = getCategoryObject(categories, change.old_value);
        let newValue = getCategoryObject(categories, change.new_value);
        let defaultLanguage = "";

        if (oldValue || newValue) {
          defaultLanguage = oldValue
            ? getDefaultLanguage(oldValue.title)
            : getDefaultLanguage(newValue.title);
        }

        return [
          oldValue ? oldValue.title[defaultLanguage] : change.old_value,
          newValue ? newValue.title[defaultLanguage] : change.new_value,
        ];
      }
      case VARIANT_CREATE: {
        const { title, unitPrice, containerPrice } = change;
        // TODO: add containerPrice to array back once supported
        const prices = [unitPrice].map(
          p => `${currency} ${p ? p.toFixed(2) : 0}`,
        );
        return ["-", [...prices, ...Object.values(title || {})].join(", ")];
      }
      case VARIANT_DELETE: {
        const { variant_id } = change;
        const variants = product.product.variants || [];
        const deleteVariants = variants.find(v => v.id === variant_id);
        if (!deleteVariants) return ["-", "-"];
        return [
          `${currency} ${deleteVariants.unitPrice.toFixed(2)}, ${Object.values(
            deleteVariants.title,
          ).join(", ")}`,
          "-",
        ];
      }
      case PRODUCT_CREATE: {
        const { title, category, description, variants } = change;
        const categoryObj = getCategoryObject(categories, category);
        const categoryString =
          categoryObj &&
          categoryObj.title[getDefaultLanguage(categoryObj.title)];
        const titleString = Object.values(title).join(", ");
        const descString = Object.values(description).join(", ");
        // for now, dont show container price
        const variantsString = variants
          .map(v => {
            const variantTitle = Object.values(v.title).join(", ");
            if (variantTitle.length) {
              return `${currency} ${v.unitPrice}, ${variantTitle}`;
            }
            return `${currency} ${v.unitPrice}`;
          })
          .join(", ");
        const totalString = [
          titleString,
          descString,
          categoryString,
          variantsString,
        ]
          .filter(Boolean)
          .join(", ");
        return ["-", totalString];
      }
      default:
        return [change.old_value, change.new_value];
    }
  };

  // TODO temp fix for the wrong structure, need to refactor!!!
  const handleChoiceProductUpdate = newPayload => {
    const { vendor_id } = newPayload;
    const originalPayload = choiceGroup[vendor_id];
    const remainingPayload = originalPayload.filter(
      payload => payload.choice_group_id !== newPayload.choice_group_id,
    );
    const hasPendingChoiceGroups = !!remainingPayload.length;

    setHasPendingChoiceGroups(prev => ({
      ...prev,
      [vendor_id]: hasPendingChoiceGroups,
    }));
  };

  return (
    <Paper className={classes.tableRoot}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left"> Vendor Name</TableCell>
            <TableCell align="left" className={classes.vendorCode}>
              Vendor Code
            </TableCell>
            <TableCell align="left" className={classes.submitDate}>
              Date Submitted
            </TableCell>
            <TableCell align="right" className={classes.files}>
              Files / Photos Uploaded
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedVendorDetails.map(vendor => (
            <React.Fragment key={vendor.vendor_id}>
              <TableRow
                onClick={() => handleClickRow(vendor.vendor_id)}
                data-enzyme="preview-row"
                data-cp="preview-row"
              >
                <TableCell align="left">
                  <Checkbox
                    checked={isVendorChecked(vendor.vendor_id)}
                    onClick={handleVendorCheck}
                    value={vendor.vendor_id}
                  />
                  {vendor.vendor_name}
                </TableCell>
                <TableCell
                  align="left"
                  onClick={e => {
                    e.stopPropagation();
                    copyToClipboard(vendor.externalRestaurantId);
                    setOpenNotification(true);
                  }}
                  className={`${classes.vendorCode} vendorCode`}
                >
                  <Box
                    p={1}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Box flex={1} color="gray">
                      <FileCopy />
                    </Box>
                    <Box flex={2}>
                      {"  "}
                      {vendor.externalRestaurantId}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  align="left"
                  className={classes.submitDate}
                  data-enzyme="submitted-date"
                >
                  {moment(vendor.timestamp).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell align="right" className={classes.files}>
                  <AttachFile
                    style={getIconStyle(vendor)}
                    onClick={() =>
                      handleAttachFileClicked(vendor.externalRestaurantId)
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow data-enzyme="data-row">
                <TableCell colSpan="12" style={{ padding: 0, border: 0 }}>
                  <Collapse
                    in={isExpanded(vendor.vendor_id)}
                    timeout="auto"
                    unmountOnExit
                    data-cp="MuiCollapse-wrapper"
                  >
                    {groupedVendor[vendor.vendor_id] && (
                      <Box bgcolor="#f9f9f9" p={3} pl={4} pr={4}>
                        {groupedVendor[vendor.vendor_id].map(product => {
                          const isCreated =
                            get(
                              product,
                              "product.changes[0].type_of_change",
                            ) === PRODUCT_CREATE;

                          return (
                            <React.Fragment key={product.product.id}>
                              <Box
                                p={0}
                                border={1}
                                display="flex"
                                flexWrap="wrap"
                                alignItems="baseline"
                                borderColor={`${
                                  isCreated ? green[600] : "grey.300"
                                }`}
                                onClick={() => {
                                  setModalStatus(true);
                                  setCurrentProduct(product);
                                }}
                                data-enzyme={`product-${product.product.id}`}
                                data-cp={`product-${product.product.id}`}
                              >
                                <Box component="span" p={1}>
                                  <Checkbox
                                    onClick={e =>
                                      handleProductCheck(e, product.product.id)
                                    }
                                    checked={isProductChecked(
                                      product.product.id,
                                    )}
                                  />
                                </Box>
                                {product.product.title && (
                                  <Box
                                    component="span"
                                    p={1}
                                    className={classes.labelContainer}
                                  >
                                    <Typography className={classes.label}>
                                      {
                                        product.product.title[
                                          getDefaultLanguage(
                                            product.product.title,
                                          )
                                        ]
                                      }
                                    </Typography>
                                  </Box>
                                )}

                                {product.changes.length > 0 && isCreated && (
                                  <Box
                                    component="span"
                                    p={1}
                                    className={classes.labelContainer}
                                  >
                                    <Typography className={classes.label}>
                                      {
                                        product.changes[0].title[
                                          getDefaultLanguage(
                                            product.changes[0].title,
                                          )
                                        ]
                                      }
                                    </Typography>
                                  </Box>
                                )}

                                {product.changes.map((change, i) => {
                                  const [oldValue, newValue] = getChanges(
                                    change,
                                    categories[product.vendor_id],
                                    product.currency && product.currency.code,
                                    product,
                                  );
                                  return (
                                    <Box
                                      component="span"
                                      p={1}
                                      key={change.id + `${i}`}
                                    >
                                      <Typography
                                        className={`${classes.lineThrough} ${changesClasses.changeValuesOld}`}
                                      >
                                        {oldValue || "-"}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        className={
                                          changesClasses.changeValuesNew
                                        }
                                      >
                                        {newValue || "-"}
                                      </Typography>
                                    </Box>
                                  );
                                })}
                              </Box>
                              <br />
                            </React.Fragment>
                          );
                        })}
                      </Box>
                    )}
                    {/* <span>{index}</span> */}
                    {choiceGroup[vendor.vendor_id] &&
                      hasPendingChoiceGroups[vendor.vendor_id] && (
                        <Box
                          p={4}
                          bgcolor="#f9f9f9"
                          data-cp="choice-group-section"
                        >
                          CHOICE GROUPS
                          {choiceGroup[vendor.vendor_id].map(
                            (choiceProduct, i) => {
                              if (choiceProduct.changes.length > 0) {
                                return (
                                  <ChoiceGroup
                                    key={`${choiceProduct.choice_group_id}`}
                                    choiceProduct={choiceProduct}
                                    handleChoiceCheck={handleChoiceCheck}
                                    setModalStatus={setModalStatus}
                                    setCurrentProduct={setCurrentProduct}
                                    // TODO temp fix for the data structure, need to refactor!!!
                                    handleChoiceProductUpdate={
                                      handleChoiceProductUpdate
                                    }
                                    setChoiceGroupNotification={
                                      setChoiceGroupNotification
                                    }
                                    isChecked={isChoiceChecked}
                                  />
                                );
                              }
                              return null;
                            },
                          )}
                        </Box>
                      )}
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <Notification
        variant={choiceGroupNotification.variant}
        open={choiceGroupNotification.open}
        handleClose={() => {
          setChoiceGroupNotification({
            message: "",
            open: false,
            variant: "success",
          });
        }}
        message={choiceGroupNotification.message}
      />
      <Notification
        variant="success"
        open={openNotification}
        handleClose={() => {
          setOpenNotification(false);
        }}
        message="Successfully copied the vendor code to clipboard"
      />
    </Paper>
  );
};

export default TableContent;
