//@flow
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import axios from "../../../App/utility/axios";
import ProductDetailModal from "../../components/ProductDetail/index";
import RejectModal from "../../components/RejectModal";
import _ from "lodash";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import styles from "./styles";
import TableContent from "./Table";
import config from "../../../App/config";
import Notification from "../../../App/components/Notification";
import Cookies from "js-cookie";
import { getCurrentCountry } from "../../../utility/information";
import countryToCodeAndSymbol from "../../utility/translation";
import {
  TYPE_OF_CHANGE,
  TYPE_OF_CHANGE_CHOICE_GROUP,
} from "../../../App/common/constant";
import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import AppRightBar from "../../../App/layouts/AppRightBar";
import ApproveButton from "../../../App/components/ApproveButton";
import RejectButton from "../../../App/components/RejectButton";
import {
  getCompulsoryProps,
  getVariantsFromProduct,
  withChangesGroupByTypeOfChange,
} from "../../utility/information";

const {
  TITLE_CHANGE,
  DESCRIPTION_CHANGE,
  VARIANT_UNITPRICE_CHANGE,
  VARIANT_TITLE_CHANGE,
  CATEGORY_CHANGE,
  VARIANT_CREATE,
  VARIANT_DELETE,
  PRODUCT_CREATE,
  VARIANT_CHOICE_GROUP_ADD,
  VARIANT_CHOICE_GROUP_DELETE,
} = TYPE_OF_CHANGE;

const { CHOICE_GROUP_CREATE } = TYPE_OF_CHANGE_CHOICE_GROUP;

const useStyles = makeStyles(styles);

const Home = ({ setCustomContext, customContext }) => {
  const classes = useStyles();
  const [successNotification, setSuccessNotification] = useState({
    open: false,
    message: "",
    variant: "",
  });

  const [errorNotification, setErrorNotification] = useState({
    open: false,
    message: "",
    variant: "",
  });

  const [loading, setLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [checks, setChecks] = useState([]);
  const [choices, setChoices] = useState([]);
  const [checkedVendors, setCheckedVendors] = useState([]);
  const [isVendorImageFetched, setIsVendorImageFetched] = useState(false);
  const [rows, setRows] = useState([]);
  const [choiceRows, setChoiceRows] = useState([]);
  const [groupedVendor, setGroupedVendor] = useState({});
  const [modalStatus, setModalStatus] = useState(false);
  const [rejectModalStatus, setRejectModalStatus] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [isCategoriesFetched, setIsCategoriesFetched] = useState(false);
  const [categories, setCategory] = useState({});
  const [vendorDetails, setVendorDetails] = useState({});
  const [
    isChangeRequestAndChoiceGroupFetched,
    setIsChangeRequestAndChoiceGroupFetched,
  ] = useState(false);

  // const [state, dispatch] = useThunkReducer(reducer, {
  //   loading: false,
  //   data: [],
  // });

  const currentCountry = customContext.currentCountry;
  const languageKeys = countryToCodeAndSymbol[currentCountry.code].map(
    country => country.code,
  );
  const [choiceGroup, setChoiceGroup] = useState({});

  const getButtonBox = () => {
    return (
      <Box>
        <ApproveButton
          className={classes.button}
          onClick={batchApprove}
          disabled={
            checks.length + choices.length === 0 ||
            approveLoading ||
            rejectLoading
          }
        >
          {approveLoading ? "Approving" : "Approve"}
        </ApproveButton>
        <RejectButton
          className={classes.button}
          disabled={
            checks.length + choices.length === 0 ||
            approveLoading ||
            rejectLoading
          }
          onClick={() => {
            setRejectModalStatus(true);
          }}
        >
          {rejectLoading ? "Rejecting" : "Reject"}
        </RejectButton>
        <Box display="inline-block">
          {checks.length + choices.length} selected
        </Box>
      </Box>
    );
  };

  const transform = data => {
    const grouped = _.groupBy(data, "vendor_id");
    return grouped;
  };

  const transformVendorDetails = groupedData => {
    return Object.values(groupedData).reduce((result, data) => {
      return {
        ...result,
        [data[0].vendor_id]: {
          vendor_id: data[0].vendor_id,
          vendor_name: data[0].vendor_name,
          externalRestaurantId: data[0].externalRestaurantId,
          timestamp: data.sort(function(a, b) {
            return a.timestamp - b.timestamp;
          })[0].timestamp,
        },
      };
    }, {});
  };

  useEffect(() => {
    currentCountry
      ? Cookies.set("country", currentCountry.code)
      : Cookies.set("country", getCurrentCountry("SG"));
    setLoading(true);
    setVendorDetails({});
    (async function() {
      try {
        const getData = async (country, status) => {
          let response = await axios(
            config.api +
              "/change_requests?platformKey=PDE_DE&country=" +
              country.code +
              "&status=" +
              status,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
            },
          );

          let value = response.data.filter(d => d.status === "pending");
          setRows(value);

          const grouped = transform(value);
          const vendorDetails = transformVendorDetails(grouped);

          setVendorDetails(prev => ({ ...prev, ...vendorDetails }));
          setGroupedVendor(grouped);
        };
        const getChoiceGroupChangeData = async (country, status) => {
          let response = await axios(
            config.api +
              "/internal/choice-groups-requests?country=" +
              country.code +
              "&status=" +
              status,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
              },
            },
          );
          let pendingChoiceGroup = response.data.filter(
            d => d.status === "pending",
          );
          setChoiceRows(pendingChoiceGroup);
          const pendingChoiceGroupGrouped = transform(pendingChoiceGroup);
          const vendorDetails = transformVendorDetails(
            pendingChoiceGroupGrouped,
          );

          setVendorDetails(prev => ({ ...prev, ...vendorDetails }));
          setChoiceGroup(pendingChoiceGroupGrouped);
        };

        await getData(currentCountry, "pending");
        await getChoiceGroupChangeData(currentCountry, "pending");
        setIsChangeRequestAndChoiceGroupFetched(true);
      } catch (error) {
        console.error(error.response || error);
      }
      setLoading(false);
    })();
  }, [currentCountry]);

  useEffect(() => {
    setGroupedVendor({});
    setRows([]);
    setChecks([]);
    setChoices([]);
    setChoiceRows([]);
    setGroupedVendor([]);
    setIsVendorImageFetched(false);
    setIsCategoriesFetched(false);
    setIsChangeRequestAndChoiceGroupFetched(false);
  }, [currentCountry]);

  useEffect(() => {
    if (
      isVendorImageFetched === false &&
      isChangeRequestAndChoiceGroupFetched &&
      Object.keys(vendorDetails).length > 0
    ) {
      Object.keys(vendorDetails).forEach((vendorId, index) => {
        const fetchImage = async () => {
          let response = await axios(
            `${config.menuAttachedFilesApi}/${vendorId}/${currentCountry.code}`,
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("token"),
              },
            },
          );
          const hasMenuImages = Array.isArray(response.data)
            ? false
            : !!response.data.menuImages.length;

          setVendorDetails(prev => ({
            ...prev,
            [vendorId]: {
              ...prev[vendorId],
              hasMenuImages,
            },
          }));
        };

        fetchImage();
        setIsVendorImageFetched(true);
      });
    }
  }, [
    isVendorImageFetched,
    vendorDetails,
    currentCountry,
    isChangeRequestAndChoiceGroupFetched,
  ]);

  useEffect(() => {
    if (!isCategoriesFetched && Object.keys(groupedVendor).length > 0) {
      Object.keys(groupedVendor).forEach((vendorId, index) => {
        const fetchVendorCategories = async () => {
          let response = await axios(
            config.api + "/change_requests/vendors/categories/" + vendorId,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Cookies.get("token"),
                "x-global-entity-id": groupedVendor[vendorId][0].code,
              },
            },
          );
          setCategory(prevState => {
            return { ...prevState, [vendorId]: response.data };
          });
        };

        fetchVendorCategories();
        setIsCategoriesFetched(true);
      });
    }
  }, [isCategoriesFetched, groupedVendor, currentCountry]);

  const saveData = async (data, type = "approved", isCreated = false) => {
    const body = {
      ...data,
      status: type,
      changes: data.changes,
    };

    // eslint-disable-next-line no-useless-concat
    return axios(config.api + `/change_requests/vendors/${data.vendor_id}`, {
      method: `${isCreated ? "post" : "patch"}`,
      data: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("token"),
        "X-Global-Entity-Id": body.code,
      },
    });
  };
  /**
   * return promise to save in batch
   */
  const _saveChoiceGroupData = async (
    data,
    type = "approved",
    isCreated = false,
    reason,
  ) => {
    const body = {
      ...data,
      status: type,
      changes: data.changes,
    };

    let choiceUpdateUrl = config.api + `/internal/choice-groups/`;
    if (!isCreated) {
      choiceUpdateUrl = choiceUpdateUrl + data.choice_group_id;
    }
    // eslint-disable-next-line no-useless-concat
    return axios(choiceUpdateUrl, {
      method: `${isCreated ? "post" : "patch"}`,
      data: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Cookies.get("token"),
        "X-Global-Entity-Id": body.code,
      },
    });
  };
  /**
   * Function to prepare choice group bulk update
   */
  const _choiceBatchUpdate = status => {
    let choicePromissList = [];
    choices.forEach(choice => {
      let isCreated = false;
      const choiceRowData = choiceRows.find(choiceRow => {
        return choiceRow.choice_group_id.toString() === choice.toString();
      });
      if (
        choiceRowData.changes[0] &&
        choiceRowData.changes[0].type_of_change === CHOICE_GROUP_CREATE
      ) {
        isCreated = true;
      }
      choicePromissList.push(
        _saveChoiceGroupData(choiceRowData, status, isCreated),
      );
    });
    return choicePromissList;
  };
  /**
   * Function to clear checks anytime
   * 1- called from batchApprove
   * 2- called from batchReject
   */
  const _clearSelectedChecks = () => {
    setChecks([]);
    setChoices([]);
  };

  const batchApprove = async () => {
    setApproveLoading(true);

    let savePromises = [];
    let approvedProducts = [];
    checks.forEach(check => {
      let isCreated = false;
      const data = rows.find(
        row => row.product.id.toString() === check.toString(),
      );
      if (
        data.changes[0] &&
        data.changes[0].type_of_change === PRODUCT_CREATE
      ) {
        isCreated = true;
      }
      approvedProducts.push(data);
      savePromises.push(saveData(data, "approved", isCreated));
    });
    /**
     * Stuffing choice group changes in same list of promise
     */
    let approvedChoices = [];
    choices.forEach(choice => {
      let approvedChoice = choiceRows.find(
        choiceRow => choiceRow.choice_group_id.toString() === choice.toString(),
      );
      approvedChoices.push(approvedChoice);
    });
    let choiceToUpdateList = _choiceBatchUpdate("approved");
    savePromises = [...savePromises, ...choiceToUpdateList];
    try {
      await Promise.all(savePromises);

      handleSaves(approvedProducts);
      handleChoiceGroupPostSaved(approvedChoices);
      setApproveLoading(false);
      _clearSelectedChecks();
      setSuccessNotification({
        open: true,
        variant: "success",
        message: `Successfully approved request${
          savePromises.length > 1 ? "s" : ""
        }`,
      });
    } catch (error) {
      console.error(error);
      setApproveLoading(false);
      setErrorNotification({
        open: true,
        variant: "error",
        message: `APN-${error.status}: ${error.message}`,
      });
    }
  };

  const batchReject = async reason => {
    setRejectLoading(true);
    let rejectPromises = [];
    let rejectedProducts = [];
    let isCreated = false;

    checks.forEach(check => {
      const product = rows.find(
        row => row.product.id.toString() === check.toString(),
      );
      product.changes.forEach(change => {
        change.reject_reason = reason;
      });
      if (product.changes[0].type_of_change === PRODUCT_CREATE) {
        isCreated = true;
      }
      rejectedProducts.push(product);
      rejectPromises.push(saveData(product, "rejected", isCreated));
    });
    /**
     * Stuffing choice group changes in same list of promise
     */
    let rejectedChoices = [];
    choices.forEach(choice => {
      let rejectedChoice = choiceRows.find(
        choiceRow => choiceRow.choice_group_id.toString() === choice.toString(),
      );
      rejectedChoice.changes.forEach(change => {
        change.reject_reason = reason;
      });
      rejectedChoices.push(rejectedChoice);
    });
    let choiceToUpdateList = _choiceBatchUpdate("rejected");
    rejectPromises = [...rejectPromises, ...choiceToUpdateList];
    try {
      await Promise.all(rejectPromises);
      handleSaves(rejectedProducts);
      handleChoiceGroupPostSaved(rejectedChoices);

      setRejectLoading(false);
      setRejectModalStatus(false);
      _clearSelectedChecks();
      setSuccessNotification({
        open: true,
        variant: "success",
        message: `Successfully rejected request${
          rejectPromises.length > 1 ? "s" : ""
        }`,
      });
    } catch (error) {
      console.error(error);
      setErrorNotification({
        open: true,
        variant: "error",
        message: `APN-${error.status}: ${error.message}`,
      });
      setRejectLoading(false);
    }
  };

  const handleProductCheck = (e, productId) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    if (isProductChecked(productId)) {
      setChecks(
        checks.filter(check => check.toString() !== productId.toString()),
      );
    } else {
      setChecks([...checks, productId.toString()]);
    }
  };

  /**
   * ChoiceGroup implementation
   * @param {*} e
   * @param {*} choiceId
   */
  const handleChoiceCheck = (e, choiceId) => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    if (isChoiceChecked(choiceId)) {
      setChoices(
        choices.filter(choice => choice.toString() !== choiceId.toString()),
      );
    } else {
      setChoices([...choices, choiceId.toString()]);
    }
  };

  const handleAttachFileClicked = (vendorId = "") => {
    window.open(`/photo/menu/${vendorId}/${currentCountry.code}`);
  };

  const handleVendorCheck = e => {
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();

    const vendorId = e.target.value;
    const associateProductIds = rows
      .filter(row => row.vendor_id === vendorId)
      .map(data => data.product.id.toString());

    let vendorChoiceGroups = [];
    if (choiceGroup[vendorId]) {
      vendorChoiceGroups = choiceGroup[vendorId].map(cg =>
        cg.choice_group_id.toString(),
      );
    }

    if (isVendorChecked(vendorId)) {
      setCheckedVendors(checkedVendors.filter(check => check !== vendorId));
      setChecks(checks.filter(check => associateProductIds.indexOf(check) < 0));
      setChoices(choices.filter(cg => vendorChoiceGroups.indexOf(cg) < 0));
    } else {
      setCheckedVendors([...checkedVendors, vendorId]);
      setChecks(_.union([...checks], associateProductIds));
      setChoices(_.union([...choices], vendorChoiceGroups));
    }
  };

  const isProductChecked = productId => {
    return (
      checks.findIndex(check => {
        return check.toString() === productId.toString();
      }) >= 0
    );
  };

  const isChoiceChecked = choiceId => {
    return (
      choices.findIndex(choice => {
        return choice.toString() === choiceId.toString();
      }) >= 0
    );
  };

  const isVendorChecked = id => {
    return (
      checkedVendors.findIndex(check => {
        return check === id;
      }) >= 0
    );
  };

  // const handleFilterClick = value => {
  //   setCountry(COUNTRY_LIST[value]);
  // };

  const handleSaves = async (products, type) => {
    setModalStatus(false);

    let remaingingProducts = [];
    let productIds = {};

    products.forEach(product => {
      productIds[product.product.id] = product.product.id;
    });

    Object.keys(groupedVendor).forEach(index => {
      groupedVendor[index].forEach(p => {
        if (!productIds[p.product.id]) {
          remaingingProducts.push(p);
        }
      });
    });

    const newVendors = transform(remaingingProducts);
    const vendorDetails = transformVendorDetails(newVendors);

    setVendorDetails(prev => ({ ...prev, ...vendorDetails }));
    setGroupedVendor({
      ...newVendors,
    });

    clearProductsFromRows(products);
  };

  const clearProductsFromRows = products => {
    let saveProductIds = products.map(product => product.product.id);
    const newRows = rows.filter(
      row => !saveProductIds.includes(row.product.id.toString()),
    );

    setRows(newRows);
  };
  const handleChoiceGroupPostSaved = async (choices, type) => {
    let remaingingChoices = [];
    let choiceIds = {};

    choices.forEach(choice => {
      choiceIds[choice.choice_group_id] = choice.choice_group_id;
    });

    Object.keys(choiceGroup).forEach(index => {
      choiceGroup[index].forEach(p => {
        if (!choiceIds[p.choice_group_id]) {
          remaingingChoices.push(p);
        }
      });
    });

    let newChoices = transform(remaingingChoices);

    setChoiceGroup({
      ...newChoices,
    });
  };

  const handleSave = async (product, type) => {
    setModalStatus(false);

    const remainingProducts = groupedVendor[product.vendor_id].filter(item => {
      return item.product.id !== product.product.id;
    });

    const vendors = {
      ...groupedVendor,
      [product.vendor_id]: remainingProducts,
    };

    let newVendors = {};

    Object.keys(vendors).forEach(index => {
      if (vendors[index].length) {
        newVendors[index] = vendors[index];
      }
    });

    setGroupedVendor({
      ...newVendors,
    });

    clearProductsFromRows([product]);
  };

  const amendChanges = async (product, form) => {
    let { title, description, category, variants } = form;
    variants = variants.map(
      ({ isPriceChanged, isTitleChanged, ...restVariant }) => ({
        ...restVariant,
      }),
    );
    let {
      title: originalTitle,
      description: originalDescription,
      variants: originalVariants,
      category: originalCategory,
    } = product.changeInfo.product;
    const originalVariantsByKey = keyBy(originalVariants, "id");
    // eg: { VariantUnitPriceChange: [{}], [{}]}
    const changesGroupByTypeOfChange = _.groupBy(
      currentProduct.changes,
      "type_of_change",
    );
    const getChangesByTypeOfChange = withChangesGroupByTypeOfChange(
      changesGroupByTypeOfChange,
    );
    const isCreated =
      get(product, "changeInfo.changes[0].type_of_change") === PRODUCT_CREATE;

    setApproveLoading(true);
    let updatedChanges = [];

    if (title && !isCreated) {
      let modifiedLanguage = {};
      updatedChanges.push(
        ...getChangesByTypeOfChange(TITLE_CHANGE).map(change => {
          modifiedLanguage[change.language] = change.language;
          return { ...change, new_value: title[change.language] };
        }),
      );

      languageKeys.forEach(language => {
        if (
          !modifiedLanguage[language] &&
          originalTitle[language] !== title[language]
        ) {
          updatedChanges.push({
            ...getCompulsoryProps(),
            old_value: originalTitle[language],
            new_value: title[language],
            type_of_change: TITLE_CHANGE,
            language,
          });
        }
      });
    }

    if (description && !isCreated) {
      let modifiedLanguage = {};

      updatedChanges.push(
        ...getChangesByTypeOfChange(DESCRIPTION_CHANGE).map(change => {
          modifiedLanguage[change.language] = change.language;
          return { ...change, new_value: description[change.language] };
        }),
      );

      languageKeys.forEach(language => {
        if (
          !modifiedLanguage[language] &&
          originalDescription[language] !== description[language]
        ) {
          updatedChanges.push({
            ...getCompulsoryProps(),
            old_value: originalDescription[language],
            new_value: description[language],
            type_of_change: DESCRIPTION_CHANGE,
            language,
          });
        }
      });
    }

    if (category && !isCreated) {
      if (originalCategory.id !== category) {
        let now = Date.now();
        updatedChanges.push({
          effectiveOn: now,
          old_value: originalCategory.id,
          id: `${Math.floor(Math.random() * Math.floor(1000000))}`,
          updatedOn: now,
          new_value: category,
          type_of_change: CATEGORY_CHANGE,
        });
      }
    }

    if (variants && !isCreated) {
      let modifiedLanguage = {};

      updatedChanges.push(
        ...getChangesByTypeOfChange(VARIANT_UNITPRICE_CHANGE).map(change => {
          const newVariant = variants.find(v => v.id === change.variant_id);
          return { ...change, new_value: newVariant.unitPrice };
        }),
      );

      updatedChanges.push(
        ...getChangesByTypeOfChange(VARIANT_TITLE_CHANGE).map(change => {
          modifiedLanguage[change.language] = change.language;
          const newVariant = variants.find(v => v.id === change.variant_id);
          return {
            ...change,
            new_value: newVariant.title[change.language],
          };
        }),
      );

      updatedChanges.push(
        ...getChangesByTypeOfChange(VARIANT_CREATE).map(change => {
          const { id, ...restChange } = change;
          const newVariant = variants.find(v => v.id === change.id);
          return {
            ...restChange,
            title: newVariant.title,
            unitPrice: newVariant.unitPrice,
            // currently containerPrice not supported, so will be 0
            containerPrice: newVariant.containerPrice,
          };
        }),
      );

      updatedChanges.push(
        // TODO in the future might need enable to let agents ammend
        ...getChangesByTypeOfChange(VARIANT_CHOICE_GROUP_ADD),
      );

      updatedChanges.push(
        // TODO in the future might need enable to let agents ammend
        ...getChangesByTypeOfChange(VARIANT_CHOICE_GROUP_DELETE),
      );

      updatedChanges.push(...getChangesByTypeOfChange(VARIANT_DELETE));

      // * Agents could update other languages variants name as well
      if (variants.length) {
        variants.forEach(variant => {
          if (variant.title && !isEmpty(variant.title)) {
            const originalVariantTitle = originalVariantsByKey[variant.id]
              ? originalVariantsByKey[variant.id].title
              : undefined;
            languageKeys.forEach(language => {
              if (
                originalVariantTitle &&
                !modifiedLanguage[language] &&
                originalVariantTitle[language] !== variant.title[language]
              ) {
                updatedChanges.push({
                  ...getCompulsoryProps(),
                  old_value: originalVariantTitle[language],
                  new_value: variant.title[language],
                  type_of_change: VARIANT_TITLE_CHANGE,
                  variant_id: variant.id,
                  language,
                });
              }
            });
          }
        });
      }
    }

    updatedChanges.push(
      ...getChangesByTypeOfChange(PRODUCT_CREATE).map(change => ({
        ...change,
        title,
        description,
        category,
        variants,
      })),
    );

    currentProduct.changes = updatedChanges;

    try {
      await saveData({ ...currentProduct }, "approved", isCreated);
      handleSave(currentProduct);
      setSuccessNotification({
        open: true,
        variant: "success",
        message: `Successfully approved request`,
      });

      setApproveLoading(false);
    } catch (error) {
      setErrorNotification({
        open: true,
        variant: "error",
        message: `APN-${error.status}: ${error.message}`,
      });
      console.error(error);
      setApproveLoading(false);
    }
  };

  const showTableContent = groupedVendor => {
    if (!_.isEmpty(groupedVendor) || !_.isEmpty(vendorDetails)) {
      return (
        <Box>
          <TableContent
            groupedVendor={groupedVendor}
            choiceGroup={choiceGroup}
            vendorDetails={vendorDetails}
            handleVendorCheck={handleVendorCheck}
            handleAttachFileClicked={handleAttachFileClicked}
            isVendorChecked={isVendorChecked}
            setModalStatus={setModalStatus}
            setCurrentProduct={setCurrentProduct}
            handleProductCheck={handleProductCheck}
            handleChoiceCheck={handleChoiceCheck}
            isProductChecked={isProductChecked}
            isChoiceChecked={isChoiceChecked}
            categories={categories}
          />
        </Box>
      );
    } else {
      return (
        <Box bgcolor="#ffff" m={5} className={classes.image}>
          <img
            alt="logo"
            src="/assets/EmptyState.png"
            width="auto"
            height="600px"
          />
        </Box>
      );
    }
  };

  useEffect(() => {
    setCustomContext(prev => ({ ...prev, name: "Menu Management Requests" }));
  }, [setCustomContext]);

  const modalRooter = document.getElementById("appbar");

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {modalRooter && (
        <AppRightBar modalRoot={modalRooter}>{getButtonBox()}</AppRightBar>
      )}

      <Container maxWidth="xl" className={classes.innerscroll}>
        {/* <Box className={classes.countryFilterContainer}>
          <CountryFilter
            countryList={COUNTRY_LIST}
            handleFilterClick={handleFilterClick}
            currentCountry={currentCountry.code}
          />
        </Box> */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            {loading ? (
              <Box p={5} className={classes.loader}>
                <CircularProgress />
              </Box>
            ) : (
              showTableContent(groupedVendor)
            )}
          </Grid>
        </Grid>
      </Container>

      <div>
        <RejectModal
          isSaving={rejectLoading}
          open={rejectModalStatus}
          handleClose={() => {
            setRejectModalStatus(false);
          }}
          handleReject={reason => {
            batchReject(reason);
          }}
        />

        {currentProduct && (
          <ProductDetailModal
            open={modalStatus}
            isSaving={approveLoading}
            handleClose={() => {
              setModalStatus(false);
            }}
            handleSave={(product, form) => {
              amendChanges(product, form);
            }}
            product={{
              variants: getVariantsFromProduct(currentProduct),
              changeInfo: currentProduct,
            }}
            activeIndex={0}
            currentCountry={currentCountry}
            categories={categories[currentProduct.vendor_id]}
          />
        )}

        <Notification
          variant={"success"}
          open={successNotification.open}
          handleClose={() => {
            setSuccessNotification({
              open: false,
              variant: "success",
              message: "",
            });
          }}
          message={successNotification.message}
        />

        <Notification
          variant={"error"}
          open={errorNotification.open}
          handleClose={() => {
            setErrorNotification({
              open: false,
              variant: "success",
              message: "",
            });
          }}
          message={errorNotification.message}
        />
      </div>
    </div>
  );
};

export default Home;
