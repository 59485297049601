import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import { makeStyles } from "@material-ui/core/styles";
import Cookies from "js-cookie";
import config from "../../config";
import styles from "./styles";
import axios from "axios";
import Box from "@material-ui/core/Box";
import { ReactComponent as FOODPANDALOGO } from "../../assets/foodpanda.svg";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);
const _classE2ELoginBox = "loginBox";

const Login = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const validateToken = async IdToken => {
    const response = await axios.post(config.api + "/login", {
      token: IdToken,
    });
    return response;
  };

  const responseGoogle = async response => {
    if (response && response.profileObj && response.profileObj.email) {
      const id_token = response.tokenId;
      try {
        setLoading(true);
        const res = await validateToken(id_token);
        setLoading(false);
        if (res.data.access_token) {
          Cookies.set("token", res.data.access_token, { expires: 7 });
          window.location.href = "/";
          if (!Cookies.get("country")) {
            Cookies.set("country", "SG");
          }
        }
      } catch (error) {
        setLoading(false);
        // We are using axios from default library instead of
        // utility/axios interceptor
        // because we don't want to redirect user to login
        // page again
        if (error.response.status === 403) {
          alert("You are not authorized to view this.");
        }
      }
    } else {
      alert("You are not authorized to view this.");
    }
  };

  const responseGoogleError = response => {
    alert("Something Went Wrong");
  };

  return (
    <div className={`${classes.container} ${_classE2ELoginBox}`}>
      <div className={classes.left}>
        <>
          <Box mt={3} display="flex" alignItems="center">
            <div className={classes.logoCircleBigWrapper}>
              <FOODPANDALOGO />
            </div>
            <Box ml={2}>
              <Typography
                variant="h5"
                style={{ fontWeight: "bold", fontSize: 20 }}
              >
                {" "}
                PandaPaw
              </Typography>
            </Box>
          </Box>
          <br />
          <Box mt={18} mb={6}>
            <Typography
              variant="h1"
              style={{ color: "black", fontWeight: "300" }}
            >
              {" "}
              Vendor Content Quality Control
            </Typography>
          </Box>
          <div>
            <GoogleLogin
              clientId={config.services.google.clientId}
              buttonText="Login with SSO"
              onSuccess={responseGoogle}
              onFailure={responseGoogleError}
              cookiePolicy={"single_host_origin"}
              disabled={loading}
              render={renderProps => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className={classes.loginButton}
                >
                  {loading ? "Logging into PandaPaw" : "Login with Google"}
                </Button>
              )}
            />
          </div>
        </>
      </div>
      <div className={classes.right}>
        <img alt="logo" src="/assets/Agent.png" style={{ height: 550 }} />
      </div>
    </div>
  );
};

export default Login;
