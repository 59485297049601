import axios from "../../../App/utility/axios";
import config from "../../../App/config";
import Cookies from "js-cookie";

export function fetching() {
  return {
    type: "REQUEST_START",
  };
}

export function fetchended() {
  return {
    type: "REQUEST_END",
  };
}

export function fetchChoiceGroupsRequest({ data }) {
  return {
    type: "FETCH_CHOICE_GROUPS_REQUESTS",
    data,
  };
}

export function fetchChoiceGroupsAsync({ vendorId, globalEntityId }) {
  return async dispatch => {
    dispatch(fetching());

    const choiceGroupData = await axios(
      `${config.api}/internal/vendors/${vendorId}/choice-groups`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Cookies.get("token"),
          "x-global-entity-id": globalEntityId,
        },
      },
    );

    dispatch(
      fetchChoiceGroupsRequest({
        data: choiceGroupData.data,
      }),
    );

    dispatch(fetchended());
  };
}
