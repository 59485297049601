export const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 9999,
    // marginTop: -66,
    background: "transparent",
    position: "absolute",
    bottom: 0,
    fontFamily: "Roboto",
  },
  logoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    flexShrink: 0,
  },
  logoCircleSmallWrapper: {
    width: "80px",
    height: "80px",
    borderRadius: "40px",
    overflow: "hidden",
  },
  logoCircleBigWrapper: {
    width: "50px",
    height: "50px",
    borderRadius: "25px",
    overflow: "hidden",
  },
  logoLink: {
    fontSize: 0,
  },
  logoImage: {
    cursor: "pointer",
  },
  logoDivider: {
    marginBottom: theme.spacing(2),
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: "100px",
    height: "100px",
  },
  nameText: {
    marginTop: theme.spacing(2),
  },
  bioText: {},
  profileDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  listSubheader: {
    color: theme.palette.text.secondary,
  },
  listItem: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      borderRadius: "0px",
      "& $listItemIcon": {
        color: theme.palette.primary.main,
        marginLeft: "-4px",
      },
    },
    "& + &": {
      marginTop: theme.spacing(),
    },
    width: "auto",
    paddingLeft: 5,
    paddingRight: 5,
  },
  ListItem: {
    marginRight: theme.spacing(1),
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    backgroundColor: "rgb(113, 113, 128)",
    "& $listItemText": {
      color: theme.palette.text.primary,
    },
    "& $listItemIcon": {
      color: theme.palette.primary.main,
      marginLeft: "-4px",
    },
    width: "auto",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    fontSize: 14,
  },
  activeListItem: {
    fontSize: 14,
    marginRight: theme.spacing(1),
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    width: "auto",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    backgroundColor: "white",
    "& $listItemText": {
      color: theme.palette.text.primary,
    },
    "& $listItemIcon": {
      color: theme.palette.primary.main,
      marginLeft: "-4px",
    },
  },
  listItemIcon: {
    marginRight: 20,
  },
  listItemText: {
    fontWeight: 600,
    fontSize: 15,
    color: theme.palette.text.secondary,
  },
  listDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  innerscroll: {
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "auto",
  },
});

export const homestyles = theme => ({
  root: {
    display: "flex",
    background: "#f4f4f4",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflow: "auto",
    marginBottom: 80,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(9),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  image: {
    textAlign: "center",
    overflowX: "auto",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  appBar: {
    position: "relative",
    background: "rgb(58, 58, 69)",
    height: 110,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    // position: "sticky",
    // top: 0,
    // boxShadow: "none",
  },
  appBarRoot: {
    position: "relative",
    minHeight: 65,
    color: "white",
    borderRadius: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: "inherit",
  },
  appBarLeft: {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
  appBarRight: {
    alignItems: "center",
    display: "flex",
    fontSize: 40,
  },
  logoCircleBigWrapper: {
    width: "32px",
    height: "32px",
    borderRadius: "25px",
    overflow: "hidden",
  },
  actionBarContainer: {
    position: "fixed",
    width: "100%",
    bottom: "0px",
    zIndex: 999,
  },
  actionBar: {
    background: "white",
    borderRadius: "5px",
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxShadow:
      "0px 0px 6px 0px rgba(0, 0, 0, 0.08),0px 6px 6px 0px rgba(0, 0, 0, 0.16)",
  },
  userIcon: {
    height: 24,
    width: 24,
    fontSize: 24,
  },
});

export default styles;
