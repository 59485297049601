import React from "react";
import Cookies from "js-cookie";
import { Redirect, Route } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";

export const ProtectedRoute = ({ component, path, ...rest }) => {
  const isAuth = Cookies.get("token");
  const Component = component;
  return (
    <Route
      exact
      path={path}
      render={props => {
        return isAuth ? (
          <ErrorBoundary>
            <Component {...rest} {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        );
      }}
    />
  );
};

export const PublicRoute = ({ children, ...rest }) => (
  <Route {...rest}>{children}</Route>
);
