const config = {
  api: process.env.REACT_APP_NIMBUS_SERVICE_CHANGE_REQUEST_ENDPOINT,
  openingTimeApi:
    process.env.REACT_APP_NIMBUS_SERVICE_OPENINGTIME_REQUEST_ENDPOINT,
  menuAttachedFilesApi:
    process.env.REACT_APP_NIMBUS_SERVICE_GET_MENU_IMAGE_ENDPOINT,
  dishPhotosApi: process.env.REACT_APP_PHOTO_SERVICE_DISH_PHOTO_ENDPOINT,
  services: {
    google: {
      clientId: process.env.REACT_APP_GOOGLE_SSO_CLIENT,
    },
  },
};

export default config;
